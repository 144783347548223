<form
  [formGroup]="meteringDialogForm"
  data-testid="regulation-metering-dialog-form"
>
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Metering
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <!-- Technology and Netting-->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Technology -->
        <prosumer-select
          fxFlex="49.9%"
          [placeholder]="'Scenario.labels.meterings.technology' | translate"
          [tooltip]="'wizard_regulation.wizard_regulation_technology'"
          [label]="'Scenario.labels.meterings.technology' | translate"
          [options]="technologyOptions"
          [control]="meteringDialogForm?.controls?.technologyId"
          [errorMessageMap]="errorMessages['technology']"
          [required]="true"
          data-testid="prosumer-metering-form-technology"
        >
        </prosumer-select>

        <!-- Netting -->
        <prosumer-select
          fxFlex="49.9%"
          [placeholder]="'Scenario.labels.meterings.netting' | translate"
          [tooltip]="'wizard_regulation.wizard_regulation_netting'"
          [label]="'Scenario.labels.meterings.netting' | translate"
          [options]="nettingOptions"
          [control]="meteringDialogForm?.controls?.netting"
          [errorMessageMap]="errorMessages['netting']"
          [required]="true"
          data-testid="prosumer-metering-form-netting"
        >
        </prosumer-select>
      </div>

      <!-- Autoconsumption Tax and Feed-in Tariff-->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Autoconsumption Tariff -->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="yearlyAutoConsumptionTax"
          inputLabel="Scenario.labels.meterings.autoconsumptionTax"
          errorMessage="Scenario.messages.metering.autoconsumptionTax"
          contextHelpMsg="wizard_regulation.wizard_regulation_autoconsumption_tax"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="prosumer-metering-form-autoconsumption-tariff"
          [minValue]="-999999999999999"
        >
        </prosumer-yearly-chart-input>

        <!-- Feed-in Tariff -->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="yearlyFeedInTariff"
          inputLabel="Scenario.labels.meterings.feedInTariff"
          errorMessage="Scenario.messages.metering.feedInTariff"
          contextHelpMsg="wizard_regulation.wizard_regulation_feed_in_tariff"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="prosumer-metering-form-feedin-tariff"
          [minValue]="-999999999999999"
        >
        </prosumer-yearly-chart-input>
      </div>

      <!-- Generation Tariff and Feed-in Premium-->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Generation Tariff -->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="yearlyGenerationTariff"
          inputLabel="Scenario.labels.meterings.generationTariff"
          errorMessage="Scenario.messages.metering.generationTariff"
          contextHelpMsg="wizard_regulation.wizard_regulation_generation_tarriff"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="prosumer-metering-form-generation-tariff"
          [minValue]="-999999999999999"
        >
        </prosumer-yearly-chart-input>

        <!-- Feed-in Premium -->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="yearlyFeedInPremium"
          inputLabel="Scenario.labels.meterings.feedInPremium"
          errorMessage="Scenario.messages.metering.feedInPremium"
          contextHelpMsg="wizard_regulation.wizard_regulation_feed_in_premium"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="prosumer-metering-form-feedin-premium"
          [minValue]="-999999999999999"
        >
        </prosumer-yearly-chart-input>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="!isViewOnly"
      [id]="data.id"
      [disabled]="meteringDialogForm.pristine"
      [data]="meteringDialogForm.value"
      [mode]="data.mode"
      [valid]="meteringDialogForm.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>
    <button
      mat-flat-button
      type="button"
      color=""
      (click)="onSaveSuccess()"
      data-testid="prosumer-metering-form-cancel-button"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>

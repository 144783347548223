<!-- You spin me right round baby right round -->
<mat-expansion-panel [expanded]="expanded">
  <div
    *ngIf="validating; else doneValidation"
    class="prosumer-message-container eyes-spacer"
  >
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
      <mat-spinner
        color="accent"
        diameter="75"
        [attr.data-testid]="'prosumer-summary-validation-indicator'"
      ></mat-spinner>
      <div fxFlex></div>
      <span class="prosumer-message1">{{
        'Scenario.messages.coherenceChecks.validating.message1' | translate
      }}</span>
      <span class="prosumer-message2">{{
        'Scenario.messages.coherenceChecks.validating.message2' | translate
      }}</span>
    </div>
  </div>
</mat-expansion-panel>
<!-- Navigation Buttons -->
<prosumer-stepper-nav
  [disabled]="validating || disabled || checkingConflicts"
  [previousDisabled]="previousDisabled"
  [previousLabel]="previousLabel"
  [previousColor]="previousColor"
  [nextDisabled]="nextDisabled || hasCoherenceError"
  [nextLabel]="nextLabel"
  [nextColor]="nextColor"
  [reverseButtons]="reverseButtons"
  (previous)="onClickPrevious()"
  (next)="onClickNext()"
>
</prosumer-stepper-nav>

<!-- TEMPLATES -->
<ng-template #doneValidation>
  <div
    *ngIf="error; else noError"
    fxLayout="column"
    fxLayoutGap="20px"
    fxLayoutAlign="center center"
    class="prosumer-message-container success"
  >
    <mat-icon aria-hidden="false" class="failure">error_outline</mat-icon>
    <span class="prosumer-message1">{{
      'Scenario.messages.coherenceChecks.failure.message1' | translate
    }}</span>
    <span class="prosumer-message2">{{
      'Scenario.messages.coherenceChecks.failure.message2' | translate
    }}</span>
  </div>
  <ng-template #noError>
    <div
      *ngIf="coherenceResults?.length > 0; else default"
      fxLayout="column"
      class="eyes-spacer-half"
    >
      <h2>{{ 'Scenario.labels.coherenceChecksTitle' | translate }}</h2>
      <div
        class="mat-error"
        [ngStyle]="{ display: hasCoherenceError ? 'block' : 'none' }"
        [innerHTML]="'Scenario.messages.coherence.invalid' | translate"
      ></div>
      <mat-list>
        <ng-container *ngFor="let step of coherenceResults; let i = index">
          <h3 mat-subheader class="prosumer-validation-header">
            {{ step?.stepLabel || 'System' }}
          </h3>
          <mat-list-item *ngFor="let validation of step?.validations">
            <mat-icon
              aria-hidden="false"
              [ngClass]="{
                warning: validation?.type === 'warning',
                failure: validation?.type === 'error'
              }"
              matListIcon
              >{{ validation?.type }}</mat-icon
            >
            <div matLine class="prosumer-validation-message white-space-unset">
              <div class="prosumer-validation-message-link">
                <div [innerHtml]="validation?.message"></div>
                <a
                  class="clickable"
                  (click)="navigateToScenarioStep(validation)"
                  ><mat-icon
                    aria-hidden="false"
                    [attr.data-testid]="'validation-' + validation?.type"
                    color="primary"
                    >arrow_circle_right</mat-icon
                  ></a
                >
              </div>
            </div>
          </mat-list-item>
          <mat-divider
            *ngIf="i + 1 !== coherenceResults?.length"
            [inset]="true"
          ></mat-divider>
        </ng-container>
      </mat-list>
    </div>
  </ng-template>
  <ng-template #default>
    <div
      fxLayout="column"
      fxLayoutGap="20px"
      fxLayoutAlign="center center"
      class="prosumer-message-container success"
    >
      <mat-icon aria-hidden="false" class="success"
        >check_circle_outline</mat-icon
      >
      <span class="prosumer-message1">{{
        'Scenario.messages.coherenceChecks.success.message1' | translate
      }}</span>
      <span class="prosumer-message2">{{
        'Scenario.messages.coherenceChecks.success.message2' | translate
      }}</span>
    </div>
  </ng-template>
</ng-template>

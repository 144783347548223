import { Directive, inject, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DetailEntity,
  ScenarioDetailStore,
} from 'prosumer-app/stores/scenario-detail';
import { filter, of, take } from 'rxjs';
import { CommentControllerService } from '../../services/comment-controller.service';
import { ScenarioFacadeService } from '../../state/scenario-facade.service';

@Directive({
  selector: '[prosumerCommentParentRefresh]',
  standalone: true,
})
@UntilDestroy()
export class CommentParentRefreshDirective {
  private readonly controller = inject(CommentControllerService);
  private readonly scenarioFacade = inject(ScenarioFacadeService);
  @Input() parentType: 'scenario' | 'detail' = 'scenario';
  @Input() detailStore: ScenarioDetailStore<DetailEntity> = {
    getSingle: () => of({}),
  } as unknown as ScenarioDetailStore<DetailEntity>;
  @Input() detailType = '';

  constructor() {
    this.controller.refreshCommentParentTrigger$
      .pipe(
        filter(() =>
          this.controller.kToRefresh(this.parentType, this.detailType),
        ),
        untilDestroyed(this),
      )
      .subscribe(() =>
        this.isParentScenario() ? this.refreshScenario() : this.refreshDetail(),
      );
  }

  private isParentScenario(): boolean {
    return this.parentType === 'scenario';
  }

  private refreshDetail(): void {
    this.detailStore
      .getSingle(this.activeParams.detailType, this.activeParams.detailId)
      .pipe(take(1))
      .subscribe();
  }

  private refreshScenario(): void {
    this.scenarioFacade.get(
      this.activeParams.scenarioId,
      this.activeParams,
      true,
    );
  }

  private get activeParams() {
    return this.controller.activeGeneralCommentsParams();
  }
}

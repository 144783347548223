<prosumer-sparkline-table
  prosumerCommentParentRefresh
  parentType="detail"
  detailType="node"
  [detailStore]="nodeStore"
  [columnsDef]="viewOnly ? nodeColumnDef : nodeWithActionsDef"
  [commentable]="true"
  idField="nodeId"
  [data]="nodesList$ | async"
  [loading]="nodesLoading$ | async"
  [hideAdd]="viewOnly"
  [hideEdit]="viewOnly"
  [hideDelete]="viewOnly"
  [hideView]="!viewOnly"
  [searchInput]="true"
  [searchPredicate]="isNameSubstring"
  (delete)="onDelete($event)"
  (edit)="onEdit($event)"
  (add)="onAdd()"
  data-testid="topology-nodes-table"
>
</prosumer-sparkline-table>

import { Utils } from 'prosumer-app/core/utils';
import {
  ListSubResources,
  ScenarioEndpointProvider,
  SingleSubResource,
} from 'prosumer-app/services/scenario-endpoint-provider';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { ScenarioDetailType } from '../scenario-detail';
import {
  CreateOpCostBody,
  GetOpCostsRsp,
  OperationalCostBE,
  OperationalCostInfo,
  OperationalCostState,
} from './operational-cost.state';
import { OperationalCostTranslator } from './operational-cost.translator';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.opCosts })
export class OperationalCostStore extends EntityStore<OperationalCostState> {
  private readonly translator = new OperationalCostTranslator();

  constructor(
    private readonly scenarioEndpoints: ScenarioEndpointProvider,
    private readonly http: HttpClient,
  ) {
    super();
  }

  create(
    equipmentId: string,
    cost: CreateOpCostBody,
  ): Observable<OperationalCostInfo> {
    return this.http
      .post<OperationalCostBE>(
        this.buildEndpointForList(equipmentId),
        this.translateToBE(cost, equipmentId),
      )
      .pipe(
        map((be) => this.translator.toFE(be)),
        tap((fe) => this.upsert(fe.id, fe)),
      );
  }

  edit(
    equipmentId: string,
    id: string,
    cost: CreateOpCostBody,
  ): Observable<OperationalCostInfo> {
    return this.http
      .patch<OperationalCostBE>(
        this.buildEndpointForSingle(id, equipmentId),
        this.translateToBE(cost, equipmentId),
      )
      .pipe(
        map((be) => this.translator.toFE(be)),
        tap((fe) => this.upsert(fe.id, fe)),
      );
  }

  getOperationalCosts(equipmentId: string): Observable<OperationalCostInfo[]> {
    return this.http
      .get<GetOpCostsRsp>(this.buildEndpointForList(equipmentId))
      .pipe(
        map((data) => data.details),
        map((bes) => this.toFEs(bes)),
        tap((fes) => this.set(fes)),
      );
  }

  getOperationalCost(
    id: string,
    equipmentId: string,
  ): Observable<OperationalCostInfo> {
    this.upsertLoadingOfEntity(id, true);
    return this.http
      .get<OperationalCostBE>(this.buildEndpointForSingle(id, equipmentId))
      .pipe(
        map((be) => this.translator.toFE(be)),
        tap((fe) => this.upsert(id, fe)),
        finalize(() => this.upsertLoadingOfEntity(id, false)),
      );
  }

  deleteOperationalCost(id: string, equipmentId: string): Observable<unknown> {
    this.upsertLoadingOfEntity(id, true);
    return this.http
      .delete<void>(this.buildEndpointForSingle(id, equipmentId))
      .pipe(
        tap(() => this.remove(id)),
        finalize(() => this.upsertLoadingOfEntity(id, false)),
      );
  }

  private translateToBE(
    body: CreateOpCostBody,
    equipmentId: string,
  ): OperationalCostBE {
    return {
      ...this.translator.toBE(body as OperationalCostInfo),
      equipmentId,
    };
  }

  private upsertLoadingOfEntity(id: string, loading: boolean): void {
    this.upsert(id, { ...this.getEntity(id), loading });
  }

  private getEntity(id: string): OperationalCostInfo {
    return Utils.resolveToEmptyObject(this.getValue().entities[id]);
  }

  private toFEs(bes: OperationalCostBE[]): OperationalCostInfo[] {
    return bes.map((be) => this.translator.toFE(be));
  }

  private buildEndpointForList(equipmentId: string): string {
    return this.scenarioEndpoints.forSubResources(
      this.buildListParams(equipmentId),
    );
  }

  private buildEndpointForSingle(id: string, equipmentId: string): string {
    return this.scenarioEndpoints.forSubResource({
      ...this.buildSingleParams(id, equipmentId),
    });
  }

  private buildListParams(equipmentId: string): ListSubResources {
    return { ...this.getCommonSubResourcesParams(), detailId: equipmentId };
  }

  private buildSingleParams(
    id: string,
    equipmentId: string,
  ): SingleSubResource {
    return { ...this.getCommonSubResourcesParams(), detailId: equipmentId, id };
  }

  private getCommonSubResourcesParams(): Omit<ListSubResources, 'detailId'> {
    return {
      dataType: ScenarioDetailType.equipment,
      subResource: ScenarioDetailType.opCosts,
    };
  }
}

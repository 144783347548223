<mat-expansion-panel
  [expanded]="expanded"
  data-testid="equipment-creation-panel"
>
  <prosumer-sparkline-table
    prosumerCommentParentRefresh
    parentType="detail"
    detailType="equipment"
    [detailStore]="equipmentStore"
    [commentable]="true"
    idField="equipmentId"
    [columnsDef]="tableMetaData"
    [references]="references$ | async"
    [data]="equipmentList$ | async"
    [searchInput]="true"
    [searchPredicate]="equipmentsSearchPredicate"
    (add)="onAddEvent($event)"
    (edit)="onEditEvent($event)"
    (delete)="onDeleteEvent($event)"
    (duplicate)="onDuplicate($event)"
    [duplicable]="true"
    [hideAdd]="isViewOnly || mode === 'read'"
    [hideEdit]="isViewOnly || mode === 'read'"
    [hideDuplicate]="isViewOnly || mode === 'read'"
    [hideDelete]="isViewOnly || mode === 'read'"
    [hideView]="!isViewOnly && mode !== 'read'"
  >
  </prosumer-sparkline-table>
</mat-expansion-panel>

<prosumer-stepper-nav
  data-testid="equipment-stepper-nav"
  [disabled]="disabled"
  [previousDisabled]="previousDisabled"
  [previousLabel]="previousLabel"
  [previousColor]="previousColor"
  [nextDisabled]="nextDisabled"
  [nextLabel]="nextLabel"
  [nextColor]="nextColor"
  [reverseButtons]="reverseButtons"
  (previous)="onClickPrevious()"
  (next)="onClickNext()"
>
</prosumer-stepper-nav>

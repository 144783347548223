<mat-expansion-panel [expanded]="true" data-testid="regulation-panel">
  <prosumer-sparkline-table
    [columnsDef]="columnsDef"
    [references]="references$ | ngrxPush"
    [searchInput]="true"
    [searchPredicate]="searchPredicate"
    [data]="regulations$ | ngrxPush"
    (add)="onAdd()"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
    [hideAdd]="isViewOnly || mode === 'read'"
    [hideEdit]="isViewOnly || mode === 'read'"
    [hideDelete]="isViewOnly || mode === 'read'"
    [hideView]="!isViewOnly && mode !== 'read'"
  >
  </prosumer-sparkline-table>
</mat-expansion-panel>

<prosumer-stepper-nav
  [disabled]="disabled"
  [previousDisabled]="previousDisabled"
  [previousLabel]="previousLabel"
  [previousColor]="previousColor"
  [nextDisabled]="nextDisabled"
  [nextLabel]="nextLabel"
  [nextColor]="nextColor"
  [reverseButtons]="reverseButtons"
  (previous)="onClickPrevious()"
  (next)="onClickNext()"
>
</prosumer-stepper-nav>

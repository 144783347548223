import { NameValidator } from 'prosumer-app/shared/validators';
import {
  LibraryService,
  ScenarioBinStore,
  ScenarioDetailType,
} from 'prosumer-app/stores';
import {
  EnergyVectorQuery,
  EnergyVectorStore,
} from 'prosumer-app/stores/energy-vector';
import {
  CreatorData,
  LoadCreator,
  LoadParents,
} from 'prosumer-app/stores/load';
import { NodeQuery, NodeStore } from 'prosumer-app/stores/node';
import {
  ScenarioVariationQuery,
  ScenarioVariationStore,
} from 'prosumer-app/stores/scenario-variation';
import { Utils } from 'prosumer-core/utils';
import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  generateShortUID,
} from 'prosumer-libs/eyes-shared';
import {
  EnergyVector,
  Library,
  LibraryLoads,
  Load,
  Node,
  Profile,
} from 'prosumer-scenario/models';
import {
  EnergyVectorCascaderService,
  LoadCascaderService,
  NodeCascaderService,
} from 'prosumer-scenario/services';
import { YearlyLoadsIntevalValidators } from 'prosumer-shared/components';
import { LibraryFilter } from 'prosumer-shared/models';
import { ProfileFormHelperService } from 'prosumer-shared/services';
import {
  getProfileId,
  getSelectedEnergyVector,
  getVariation,
} from 'prosumer-shared/utils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LoadFormDialogData } from './loads-dialog.model';

@UntilDestroy()
@Component({
  selector: 'prosumer-loads-dialog',
  templateUrl: './loads-dialog.component.html',
  styleUrls: ['./loads-dialog.component.scss'],
  providers: [
    ProfileFormHelperService,
    EnergyVectorCascaderService,
    LoadCascaderService,
    TitleCasePipe,
    NodeCascaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadsDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  isMultiNode: boolean;
  startYear: number;
  endYear: number;

  scenarioId: string;
  caseId: string;
  projectId: string;
  scenarioName: string;

  loadForm: UntypedFormGroup;
  libraries: Array<Library> = [];
  selectedLoads: Array<string>;

  libraryLoading$: Observable<boolean>;
  binaryLoading$: Observable<boolean>;
  libraries$: Observable<any>;

  energyVectorOptions: Array<EnergyVector>;
  nodeOptions: Array<Node>;
  scenarioVariationOptions: Array<FormFieldOption<any>> = [];
  // options
  energyVectorOptions$: Observable<Array<EnergyVector>>;
  scenarioVariationOptions$: Observable<Array<FormFieldOption<any>>>;
  nodeOptions$: Observable<Array<Node>>;

  nodeWarningShown$ = new BehaviorSubject<boolean>(false);

  isViewOnly: boolean;

  filters$ = new BehaviorSubject<LibraryFilter>({});

  activeIndex = 0;

  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages');
  previousLibraryRef = {};

  loading$ = this.jesus.selectLoading();

  get loadIdControl(): UntypedFormControl {
    return this.loadForm.controls.id as UntypedFormControl;
  }

  get loadNameControl(): UntypedFormControl {
    return this.loadForm.controls.name as UntypedFormControl;
  }

  get energyVectorControl(): UntypedFormControl {
    return this.loadForm.controls.energyVector as UntypedFormControl;
  }

  get nodeControl(): UntypedFormControl {
    return this.loadForm.controls.nodes as UntypedFormControl;
  }

  get profilesArray(): UntypedFormArray {
    return this.loadForm.controls.profiles as UntypedFormArray;
  }

  get scenarioVariationControl(): UntypedFormControl {
    return this.loadForm.controls.scenarioVariation as UntypedFormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LoadFormDialogData,
    public dialogRef: MatDialogRef<LoadFormDialogData>,
    public formBuilder: UntypedFormBuilder,
    public binaryStore: ScenarioBinStore,
    public libraryService: LibraryService,
    public profileFormHelperService: ProfileFormHelperService,
    private _formService: FormService,
    private _evCascaderService: EnergyVectorCascaderService,
    private _loadCascaderService: LoadCascaderService,
    private _nodeCascaderService: NodeCascaderService,
    private evQuery: EnergyVectorQuery,
    private nodeQuery: NodeQuery,
    private variationQuery: ScenarioVariationQuery,
    private evStore: EnergyVectorStore,
    private nodeStore: NodeStore,
    private variationStore: ScenarioVariationStore,
    private readonly jesus: LoadCreator,
  ) {
    super();
    this.loadForm = this.initLoadForm();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.initObservables();
    this.initOptions();
    this.initFilterHandler();
    this.loadLibrary();
    this.initData();
    this.initEnergyVectorValueChange();
    this.initNodeValueChange();
  }

  ngAfterViewInit(): void {
    this.addProfilesCtrlValidator();
  }

  initOptions() {
    this.evStore
      .getEnergyVectors({ dataType: ScenarioDetailType.energyVector })
      .pipe(take(1))
      .subscribe();

    this.nodeStore.getNodes().pipe(take(1)).subscribe();
    this.variationStore
      .getVariations({ dataType: ScenarioDetailType.variation })
      .pipe(take(1))
      .subscribe();

    this.energyVectorOptions$ = this.evQuery
      .selectActiveVectors()
      .pipe(untilDestroyed(this));
    this.scenarioVariationOptions$ = this.variationQuery
      .selectActiveVariation()
      .pipe(
        tap((variations) => {
          this.scenarioVariationOptions = variations;
        }),
        untilDestroyed(this),
      );
    this.nodeOptions$ = this.nodeQuery
      .selectActiveNodes()
      .pipe(untilDestroyed(this));
  }

  initData() {
    const {
      endUseLoad,
      mode,
      scenarioIdentity,
      isMultiNode,
      startYear,
      endYear,
      scenarioVariationOptions,
    } = this.data || {};

    const { id, name, nodes, energyVector, profiles, scenarioVariation } =
      endUseLoad || {};

    this.projectId = scenarioIdentity.projectId;
    this.caseId = scenarioIdentity.caseId;
    this.scenarioId = scenarioIdentity.scenarioId;

    this.isMultiNode = isMultiNode;
    this.startYear = startYear;
    this.endYear = endYear;
    this.loadIdControl.patchValue(id, { emitEvent: false });
    this.isViewOnly = this.data.isViewOnly || false;

    combineLatest([
      this.loadForm.get('name').valueChanges,
      this.loadForm.get('scenarioVariation').valueChanges,
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.validateLoadNameScenarioVariationCombination();
        this.loadForm.controls.name.clearAsyncValidators();
        this.loadForm.controls.scenarioVariation.clearAsyncValidators();
      });

    if (!this.isMultiNode || this.isEditOrDuplicateMode(this.data)) {
      this.nodeControl.patchValue(nodes, { emitEvent: false });
    }

    if (this.isEditOrDuplicateMode(this.data)) {
      this.scenarioVariationControl.patchValue(scenarioVariation);
      this.loadNameControl.patchValue(name);
      this.energyVectorControl.patchValue(energyVector, {
        emitEvent: false,
      });
      this.profilesArray.patchValue(profiles, { emitEvent: false });
      this.loadBinaryData(profiles[0]);
      this.initPreviousLibraryRef(profiles);
      this.scenarioName = this.data.scenarioVariationOptions[0].value;
      this.scenarioVariationOptions = scenarioVariationOptions;
    }
    this.initValidators();
  }

  isEditOrDuplicateMode(data) {
    return ['edit', 'duplicate'].includes(data.mode);
  }

  initObservables() {
    this.binaryLoading$ = this.binaryStore.loading$;
    this.libraryLoading$ = this.libraryService.loading$;
  }

  initValidators() {
    const skipLoad = this.data.mode === 'edit' ? this.data.endUseLoad : null;
    this.loadNameControl.setAsyncValidators(
      CustomValidators.dataExist(this.data.endUseLoads$, 'name', skipLoad, {
        scenarioVariation: this.scenarioVariationControl,
      }),
    );

    this.scenarioVariationControl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => {
        {
          this.loadNameControl.updateValueAndValidity();
        }
      });
    this.energyVectorControl.updateValueAndValidity();
  }

  validateLoadNameScenarioVariationCombination() {
    let hasDuplicate = false;
    const variation = getVariation(this.scenarioVariationControl.value);
    this.data.endUseLoads$.forEach((loads) => {
      hasDuplicate = loads.some(
        ({ name, scenarioVariation, id }) =>
          variation === scenarioVariation &&
          this.loadNameControl.value === name &&
          this.data.endUseLoad.id !== id,
      );
    });

    if (hasDuplicate && this.scenarioVariationOptions.length <= 1) {
      this.loadNameControl.setErrors({ dataExist: true });
    } else if (hasDuplicate && this.scenarioVariationControl?.value) {
      this.scenarioVariationControl.setErrors({ combinationExist: true });
      this.loadNameControl.setErrors({ combinationExist: true });
    } else if (!this.loadNameControl.value) {
      this.loadNameControl.setErrors({ required: true });
      this.scenarioVariationControl.setErrors(null);
    } else if (!this.loadNameControl.errors?.invalidCharacter) {
      this.loadNameControl.setErrors(null);
      this.scenarioVariationControl.setErrors(null);
    }
  }

  initFilterHandler() {
    combineLatest([
      this.energyVectorControl.valueChanges.pipe(
        startWith(this.energyVectorControl.value),
        getSelectedEnergyVector(
          ((this.data || ({} as any)).endUseLoad || ({} as any)).energyVector,
          this.energyVectorOptions$,
        ),
      ),
      this.filters$,
    ])
      .pipe(
        filter(([energyVector, filters]) => !!energyVector && !!filters),
        mergeMap(([energyVector, filters]) =>
          this.libraryService.filterLibraryByParams$(
            filters.vectorType,
            filters.businessType,
            filters.buildingType,
            filters.buildingCategory,
            filters.location,
            energyVector,
          ),
        ),
        map((libs) => libs.filter((lib) => lib.type === 'loads')),
        this.takeUntil(),
      )
      .subscribe((value) => (this.libraries = value));
  }

  initEnergyVectorValueChange() {
    const revertVectorValue = (currentEnergyVector) => {
      this.loadForm.controls.energyVector.patchValue(currentEnergyVector);
    };
    this.loadForm
      .get('energyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        const currentEnergyVector = this.loadForm.value['energyVector'];
        const isNettingAffected =
          this._loadCascaderService.isNettingAffectedByEnergyVector(
            this.loadForm.value['id'],
            [currentEnergyVector],
            this.data.currentNetting,
          );
        this._evCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            if (!isOk) {
              revertVectorValue(currentEnergyVector);
            }
          });
      });
  }

  initNodeValueChange() {
    this.loadForm
      .get('nodes')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.nodeWarningShown$.value && this.loadForm.dirty) {
          return;
        }

        const isNettingAffected =
          this._loadCascaderService.isNettingAffectedByNode(
            this.loadForm.value['id'],
            value?.nodes,
            this.data.currentNetting,
          );

        if (isNettingAffected) {
          this.nodeWarningShown$.next(true);
        }

        this._nodeCascaderService.showCascadingWarningDialog([
          isNettingAffected,
        ]);
      });
  }

  initLoadForm(): UntypedFormGroup {
    return this.formBuilder.group({
      id: this.formBuilder.control(''),
      name: this.formBuilder.control('', NameValidator.validWithCore()),
      energyVector: this.formBuilder.control(''),
      nodes: this.formBuilder.control(undefined),
      profiles: this.formBuilder.control(''),
      activeProfile: this.formBuilder.control(''),
      scenarioVariation: this.formBuilder.control('basecase'),
    });
  }

  loadLibrary(): void {
    this.libraryService.get('loads').pipe(take(1)).subscribe();
  }

  loadBinaryData(selectedProfile: Profile) {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1))
      .subscribe((loads) => {
        if (!!loads) {
          const profile = (loads || ({} as LibraryLoads)).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'bin',
          });
        }
      });
  }

  loadBinaryData$(selectedProfile: Profile) {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      const binDataId = getProfileId(selectedProfile);
      return this.binaryStore
        .get(
          this.projectId,
          this.caseId,
          this.scenarioId,
          selectedProfile.location,
          binDataId,
          false,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }

  onSelectInterval(value: { selectedProfile: Profile; index: number }) {
    this.activeIndex = value.index;
    if (
      (this.data.mode === 'duplicate' &&
        this.profileFormHelperService.forBinDownload(
          this.data.endUseLoad.profiles,
          value.selectedProfile.localId,
        )) ||
      (this.data.mode === 'edit' &&
        this.profileFormHelperService.forBinDownload(
          this.data.persistedProfiles,
          value.selectedProfile.localId,
        ))
    ) {
      this.loadBinaryData(value.selectedProfile);
    }
  }

  onSelectLibrary(library: Library) {
    if (!!library && this.isPrevSelectedLibrary(library.id)) {
      this.previousLibraryRef[this.activeIndex] = library.id;
      this.libraryService
        .getLoads(library.id)
        .pipe(
          filter((loads) => !!loads),
          take(1),
        )
        .subscribe((loads) => {
          const profile = (loads || ({} as LibraryLoads)).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'lib',
          });
          // this.selectedLoads = (loads || {} as LibraryLoads).data;
        });
    }
  }

  onApplyLibraryFilters(filters: LibraryFilter) {
    this.filters$.next(filters);
  }

  onClose() {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.hasValidChanges()) {
      this.saveLoad();
    }
  }

  hasValidChanges(): boolean {
    const form = Utils.resolveToEmptyObject(this.loadForm);
    return [!form.pristine, form.valid].every(Boolean);
  }

  private saveLoad(): void {
    if (this.isEditMode()) {
      this.editLoad(this.buildCreatorData());
    } else {
      this.createLoad(this.buildCreatorData());
    }
  }

  private isEditMode(): boolean {
    return this.data.mode === 'edit';
  }

  private isDuplicateMode(): boolean {
    return this.data.mode === 'duplicate';
  }

  private editLoad(data: CreatorData): void {
    this.jesus
      .update(data)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.dialogRef.close());
  }

  private createLoad(data: CreatorData): void {
    this.jesus
      .create(data, this.isDuplicateMode())
      .pipe(untilDestroyed(this))
      .subscribe(() => this.dialogRef.close());
  }

  private buildCreatorData(): CreatorData {
    return { ...this.getFormValueInLoad(), ...this.buildLoadParents() };
  }

  private buildLoadParents(): LoadParents {
    return { ...this.data.scenarioIdentity };
  }

  private getFormValueInLoad(): Load {
    return this.toLoad(this.loadForm.getRawValue());
  }

  private toLoad(form: unknown): Load {
    return {
      id: this.loadIdControl.value,
      name: this.loadNameControl.value,
      energyVector: this.energyVectorControl.value,
      nodes: this.isMultiNode
        ? form['nodes']['nodes'] || form['nodes'] || []
        : form['nodes'],
      profiles: this.getProfiles(this.profilesArray.value),
      scenarioVariation: this.scenarioVariationControl.value,
    };
  }

  private getProfiles(profiles: Profile[]): Profile[] {
    if (this.isDuplicateMode()) {
      return profiles.map((profile) => ({
        ...profile,
        originalLocalId: profile.localId,
        localId: generateShortUID(),
      }));
    }
    return profiles;
  }

  private initPreviousLibraryRef = (profiles: Profile[]): void => {
    profiles.forEach((profile: Profile, index: number) => {
      this.previousLibraryRef[index] = profile.library;
    });
  };

  private isPrevSelectedLibrary = (libraryId: string): boolean =>
    !this.previousLibraryRef[this.activeIndex] ||
    this.previousLibraryRef[this.activeIndex] !== libraryId;

  private addProfilesCtrlValidator() {
    this.loadForm.controls.profiles.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(false, true),
    ]);
    this.loadForm.controls.profiles.updateValueAndValidity();
  }
}

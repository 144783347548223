import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared/eyes-shared.module';
import { CommentHrefComponent } from 'prosumer-scenario/components/comment-href/comment-href.component';
import { ScenarioActionsModule } from 'prosumer-scenario/components/scenario-actions/scenario-actions.module';
import { ScenarioNameModule } from 'prosumer-scenario/components/scenario-name/scenario-name.module';
import { CommentsComponent } from 'prosumer-scenario/containers/comments/comments.component';
import { SharedModule } from 'prosumer-shared/shared.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { CommentParentRefreshDirective } from 'prosumer-app/+scenario/directives/comment-parent-refresh';
import { ScenarioDetailsComponent } from './scenario-details.component';

@NgModule({
  declarations: [ScenarioDetailsComponent],
  imports: [
    EyesSharedModule.forFeature(),
    SharedModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    LetDirective,
    PushPipe,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    ScenarioActionsModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    RouterModule,
    ScenarioNameModule,
    CommentHrefComponent,
    CommentsComponent,
    CommentParentRefreshDirective,
  ],
  exports: [ScenarioDetailsComponent],
})
export class ScenarioDetailsModule {}

<mat-progress-bar
  *ngIf="binaryLoading$ | async"
  color="accent"
  mode="indeterminate"
  data-testid="mobility-routes-dialog-progress-bar"
></mat-progress-bar>
<form [formGroup]="routesForm" data-testid="mobility-routes-dialog-form">
  <h2 mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{ 'Scenario.labels.wizard.mobility.headers.routes' | translate }}
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-input
          type="string"
          fxFlex="100%"
          module="Scenario"
          formFieldName="mobility.name"
          data-testid="mobility-routes-name"
          [inputTooltip]="'wizard_mobility.wizard_mobility_name'"
          [control]="routesForm?.controls?.name"
          [required]="true"
        ></prosumer-input>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-yearly-loads-interval
          formControlName="profileRoutes"
          [required]="true"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          [defaultLocation]="data?.profileRoutesLocation"
          [useLibraryPanel]="false"
          [mode]="data.mode"
          [loading]="binaryLoading$ | async"
          [alwaysCustom]="true"
          [yearlyLoadEnabled]="false"
          [yearlyLoadMessages]="yearlyLoadMessage"
          [nodeOptions]="data?.nodeOptions"
          [allowStringInput]="true"
          (selectedTabChange)="onSelectInterval($event)"
        ></prosumer-yearly-loads-interval>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="!isViewOnly"
      [id]="data?.id"
      [disabled]="routesForm.invalid || (binaryLoading$ | async)"
      [data]="routesForm?.value"
      [mode]="data?.mode"
      [valid]="routesForm?.valid"
      (ok)="onClose()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>
    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [disabled]="binaryLoading$ | async"
      data-testid="mobility-routes-cancel-btn"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        data?.close || ('Generic.labels.cancel' | translate)
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommentComponent } from 'prosumer-scenario/components/comment/comment.component';
import {
  GeneralCommentsParams,
  GetCommentsResource,
} from 'prosumer-scenario/models/comment.types';
import {
  CommentControllerService,
  CommentService,
} from 'prosumer-scenario/services';
import {
  combineLatest,
  distinctUntilKeyChanged,
  finalize,
  Observable,
  of,
  tap,
} from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Injector,
  OnDestroy,
  runInInjectionContext,
  ViewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { Resource } from 'prosumer-app/shared/models/resource.types';

@Component({
  selector: 'prosumer-comments',
  standalone: true,
  imports: [
    CommentComponent,
    MatButtonModule,
    MatIconModule,
    CommentComponent,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class CommentsComponent implements OnDestroy {
  private readonly service = inject(CommentService);
  private readonly injector = inject(Injector);
  readonly controller = inject(CommentControllerService);
  readonly dialogData = inject(MAT_DIALOG_DATA, { optional: true });

  readonly currentUser = toSignal(inject(UserFacadeService).clientUser$);
  readonly editCommentBag = {};
  readonly emptySignal = toSignal(of({} as Resource));
  comments = toSignal(of({} as GetCommentsResource));
  addComment = toSignal(of({} as Resource));
  editComment = toSignal(of({} as Resource));
  deleteComment = toSignal(of({} as Resource));
  isAdding = false;

  @ViewChild('commentList') commentList: ElementRef;

  constructor() {
    this.selectBothDistinctParams()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        runInInjectionContext(this.injector, () => {
          this.comments = toSignal(this.getCommentsUsingActiveParams());
        });
      });
  }

  private selectBothDistinctParams() {
    return combineLatest([
      this.selectParamsWithDistinctScenarioId(),
      this.selectParamsWithDistinctDetailId(),
    ]);
  }

  private selectParamsWithDistinctScenarioId() {
    return toObservable(this.controller.activeGeneralCommentsParams).pipe(
      distinctUntilKeyChanged('scenarioId'),
    );
  }

  private selectParamsWithDistinctDetailId() {
    return toObservable(this.controller.activeGeneralCommentsParams).pipe(
      distinctUntilKeyChanged('detailId'),
    );
  }

  onAddComment(): void {
    this.isAdding = true;
    setTimeout(() => this.scrollToBottom(), 0);
  }

  onClose(): void {
    this.controller.showComments.set(false);
  }

  ngOnDestroy(): void {
    this.controller.showComments.set(false);
    this.controller.activeGeneralCommentsParams.set(null);
  }

  onAddSave(content: string) {
    runInInjectionContext(this.injector, () => {
      this.addComment = toSignal(this.addCommentUsingActiveParams(content));
    });
  }

  onEditSave(commentId: string, content: string) {
    runInInjectionContext(this.injector, () => {
      this.editCommentBag[commentId] = toSignal(
        this.service.editGeneralComment(
          { ...this.activeGeneralParams, commentId },
          content,
        ),
      );
    });
  }

  onDelete(commentId: string) {
    runInInjectionContext(this.injector, () => {
      this.deleteComment = toSignal(
        this.deleteCommentUsingActiveParams(commentId),
      );
    });
  }

  private scrollToBottom() {
    this.commentList.nativeElement.scrollTo({
      top: this.commentList.nativeElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  private get activeGeneralParams(): GeneralCommentsParams {
    return this.controller.activeGeneralCommentsParams() as GeneralCommentsParams;
  }

  private getCommentsUsingActiveParams(): Observable<GetCommentsResource> {
    return this.service
      .getGeneralComments(this.activeGeneralParams)
      .pipe(tap(() => (this.isAdding = false)));
  }

  private addCommentUsingActiveParams(content: string) {
    return this.service
      .createGeneralComment(this.activeGeneralParams, content)
      .pipe(finalize(() => this.controller.refreshCommentParent()));
  }

  private deleteCommentUsingActiveParams(commentId: string) {
    return this.service
      .deleteGeneralComment({
        ...this.activeGeneralParams,
        commentId,
      })
      .pipe(finalize(() => this.controller.refreshCommentParent()));
  }
}

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { UPSERTER, Upserter } from 'prosumer-app/stores/scenario-detail';
import { Coerce } from 'prosumer-core/utils';
import { EntityUpsertionTrigger } from 'prosumer-scenario/directives/entity-upsertion-trigger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

@Component({
  selector: 'prosumer-entity-upsert-button',
  templateUrl: './entity-upsert-button.component.html',
  styleUrls: ['./entity-upsert-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityUpsertButtonComponent extends EntityUpsertionTrigger<unknown> {
  constructor(
    @Inject(UPSERTER) private readonly upserter: Upserter,
    private readonly notifs: NotificationsService,
  ) {
    super();
  }

  create(data: unknown): Observable<unknown> {
    return this.upserter
      .create(data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  edit(id: string, data: unknown): Observable<unknown> {
    return this.upserter
      .edit(id, data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.notifs.showError(this.extractError(error));
    return throwError(() => error);
  }

  private extractError(from: HttpErrorResponse): string {
    return Coerce.toString(
      Coerce.toObject(from.error).error,
      this.getDefaultErrorMsg(),
    );
  }

  private getDefaultErrorMsg(): string {
    return 'Something went wrong :(';
  }
}

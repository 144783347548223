<form
  class="node-form"
  [formGroup]="nodeForm"
  data-testid="topology-nodes-dialog-form"
>
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Node
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <!-- Node Name -->
      <prosumer-input
        fxFlex="50%"
        type="string"
        module="Scenario"
        formFieldName="nodes.name"
        class="eyes-maximize-width"
        data-testid="topology-nodes-dialog-input-name"
        [control]="nameCtrl"
        [required]="true"
      >
      </prosumer-input>
      <!-- Node Surface -->
      <prosumer-input
        fxFlex="50%"
        type="number"
        module="Scenario"
        class="eyes-maximize-width"
        formFieldName="nodes.surface"
        data-testid="topology-nodes-dialog-input-surface"
        [inputTooltip]="'wizard_topology.wizard_topology_surface'"
        [control]="surfaceCtrl"
      >
      </prosumer-input>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      [disabled]="!isFormValid || isFormPristine"
      [mode]="data.mode"
      [valid]="isFormValid"
      [id]="data.nodeData?.nodeId"
      [data]="nodeForm.value"
      (ok)="onClose()"
      data-testid="topology-nodes-dialog-btn-confirm"
    >
      <!-- (attempt)="onSaveAttempt()" -->
    </prosumer-entity-upsert-button>
    <button
      data-testid="cancel-button"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
    >
      <span>{{ 'Generic.labels.cancel' | translate }}</span>
    </button>
  </div>
</form>

<prosumer-sparkline-table
  prosumerCommentParentRefresh
  parentType="detail"
  detailType="line"
  [detailStore]="lineStore"
  [commentable]="true"
  idField="lineId"
  [references]="idNames$ | ngrxPush"
  [data]="data$ | ngrxPush"
  [columnsDef]="columnsDef"
  [searchInput]="true"
  [searchPredicate]="isNameSubstring"
  [hideAdd]="viewOnly"
  [hideEdit]="viewOnly"
  [hideDelete]="viewOnly"
  [hideView]="!viewOnly"
  [hideDuplicate]="viewOnly"
  [duplicable]="true"
  [loading]="loading$ | ngrxPush"
  (add)="onAdd()"
  (edit)="onEdit($event)"
  (delete)="onDelete($event)"
  (duplicate)="onDuplicate($event)"
></prosumer-sparkline-table>

<form [formGroup]="form" data-testid="equipment-form">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.shortCircuit.equipment.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content fxLayout="column">
    <!-- Equipments -->
    <mat-form-field
      [@fadeIn]
      class="eyes-maximize-width"
      appearance="outline"
      data-testid="sc-equipment-equipment-select-field"
    >
      <mat-label>
        {{
          'Scenario.dialog.frequencyControl.shortCircuit.equipment.labels.equipment'
            | translate
        }}
      </mat-label>

      <mat-select
        formControlName="equipmentId"
        data-testid="sc-equipment-select"
        [hideSingleSelectionIndicator]="true"
        [placeholder]="
          'Scenario.dialog.frequencyControl.shortCircuit.equipment.placeholders.equipment'
            | translate
        "
      >
        <mat-option *ngFor="let eq of equipmentOptions" [value]="eq.id">
          {{ eq.name }}
        </mat-option>
      </mat-select>
      <mat-icon
        aria-hidden="false"
        matSuffix
        class="eyes-input-tooltip-anchor"
        [prosumerTooltip]="'wizard_frequency_control.short_circuit_equipment'"
        data-testid="sc-equipment-tooltip"
      >
        help
      </mat-icon>
      <mat-error
        *ngFor="let error of equipmentIdControl?.errors | keyvalue"
        [ngSwitch]="error.key"
      >
        <div
          *ngSwitchCase="'required'"
          [innerHtml]="
            'Scenario.dialog.frequencyControl.shortCircuit.equipment.errors.equipment.required'
              | translate
          "
        ></div>
        <div
          *ngSwitchCase="'dataExist'"
          [style.line-height.px]="12"
          [innerHtml]="
            'Scenario.dialog.frequencyControl.shortCircuit.equipment.errors.equipment.dataExist'
              | translate
          "
        ></div>
      </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Short Circuit Factor -->
      <prosumer-yearly-chart-input
        fxFlex="50%"
        formControlName="shortCircuitFactor"
        inputLabel="Scenario.dialog.frequencyControl.shortCircuit.equipment.labels.factor"
        errorMessage="Scenario.dialog.frequencyControl.shortCircuit.equipment.errors.scFactor"
        contextHelpMsg="wizard_frequency_control.short_circuit_factor"
        [attr.data-testid]="'equipment-form-factor'"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
        data-testid="sc-equipment-factor"
      ></prosumer-yearly-chart-input>

      <!-- Short Circuit Threshold -->
      <prosumer-yearly-chart-input
        fxFlex="50%"
        formControlName="shortCircuitThreshold"
        inputLabel="Scenario.dialog.frequencyControl.shortCircuit.equipment.labels.threshold"
        errorMessage="Scenario.dialog.frequencyControl.shortCircuit.equipment.errors.scThreshold"
        contextHelpMsg="wizard_frequency_control.short_circuit_threshold"
        [attr.data-testid]="'equipment-form-threshold'"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
        data-testid="sc-equipment-threshold"
      ></prosumer-yearly-chart-input>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      [id]="data.equipment?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>
    <button
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
      [attr.data-testid]="'equipment-form-cancel'"
    >
      <span
        [attr.data-testid]="'equipment-form-cancel-label'"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span [attr.data-testid]="'equipment-form-close-label'">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>

<form
  [formGroup]="form"
  (ngSubmit)="onConfirm()"
  data-testid="sr-activation-dialog-form"
>
  <h2 mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.spinningReserves.activation.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Energy Vector -->
        <mat-form-field
          [@fadeIn]
          class="eyes-maximize-width"
          appearance="outline"
          data-testid="sr-activation-ev-select-field"
        >
          <mat-label data-testid="activation-form-ev-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.activation.labels.energyVector'
              | translate
          }}</mat-label>

          <mat-select
            required
            formControlName="energyVectorId"
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.activation.placeholders.energyVector'
                | translate
            "
            [hideSingleSelectionIndicator]="true"
            data-testid="activation-form-ev-select"
          >
            <mat-option
              *ngFor="let energyVector of energyVectorOptions"
              [value]="energyVector.value"
            >
              {{ energyVector.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            aria-hidden="false"
            data-testid="activation-form-ev-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.reserve_energy_vector'"
          >
            help
          </mat-icon>
          <mat-error
            data-testid="activation-form-ev-error"
            *ngFor="let error of energyVectorIdControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.energyVector.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'dataExist'"
              [style.line-height.px]="12"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.energyVector.dataExist'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Direction -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="activation-form-direction-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.activation.labels.direction'
              | translate
          }}</mat-label>

          <mat-select
            required
            formControlName="direction"
            data-testid="activation-form-direction-option"
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.activation.placeholders.direction'
                | translate
            "
            [hideSingleSelectionIndicator]="true"
          >
            <mat-option
              *ngFor="let direction of sortedDirections"
              [value]="direction.key"
            >
              {{ direction.value }}
            </mat-option>
          </mat-select>

          <mat-icon
            aria-hidden="false"
            data-testid="activation-form-direction-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.direction'"
          >
            help
          </mat-icon>

          <mat-error
            data-testid="activation-form-direction-error"
            *ngFor="let error of directionControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.direction.required'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Activation Rate -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="activation-form-rate-title">
            {{
              'Scenario.dialog.frequencyControl.spinningReserves.activation.labels.rate'
                | translate
            }}
          </mat-label>

          <input
            data-testid="activation-form-rate-input"
            matInput
            formControlName="rate"
            required
            type="number"
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.activation.placeholders.rate'
                | translate
            "
          />

          <!-- TODO Updated contextual helper-->
          <mat-icon
            aria-hidden="false"
            data-testid="activation-form-rate-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'Average activation rate. \n Unit : -/h. \n Constraint : The parameter may take any positive value. \n Comment : This parameter is used to define the average activation rate of the system. This parameter is useful to define energy that we need to keep in storages and for cost arbitrages.'
            "
          >
            help
          </mat-icon>

          <mat-error
            data-testid="activation-form-rate-error"
            *ngFor="let error of rateControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.rate.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'min'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.rate.min'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Activation Duration -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="activation-form-duration-title">
            {{
              'Scenario.dialog.frequencyControl.spinningReserves.activation.labels.duration'
                | translate
            }}
          </mat-label>

          <input
            matInput
            formControlName="duration"
            required
            type="number"
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.activation.placeholders.duration'
                | translate
            "
            data-testid="activation-form-duration-input"
          />

          <!-- TODO Updated contextual helper-->
          <mat-icon
            aria-hidden="false"
            data-testid="activation-form-duration-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'Average activation duration. \n Unit : h. \n Constraint : The parameter may take any positive value. \n Comment : This parameter is used to define the average activation duration of the reserve. This parameter is useful to define energy that we need to keep in storages and for cost arbitrages.'
            "
          >
            help
          </mat-icon>

          <mat-error
            data-testid="activation-form-duration-error"
            *ngFor="let error of durationControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.duration.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'min'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.duration.min'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      [id]="data.currentReserveActivation?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>
    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [attr.data-testid]="'activation-form-cancel'"
    >
      <span
        [attr.data-testid]="'activation-form-cancel-label'"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span [attr.data-testid]="'activation-form-close-label'">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import {
  BaseComponent,
  InversePipe,
  KiloPipe,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import {
  CompareCashFlowResultItem,
  CompareCashFlowResultItemValue,
} from 'prosumer-scenario/models';
import { ColorShade } from 'prosumer-shared/modules/chartjs';
import {
  BarDatum,
  StackedBarMeta,
} from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { groupBy, map, mergeMap, toArray } from 'rxjs/operators';

const DEFAULT_BAR_WIDTH = 2;
const DEFAULT_SPACING = 8.1;
const DEFAULT_OFFSET = 74.6;
const DEFAULT_GROUP_PADDING = 5;
const DEFAULT_BAR_PADDING = 3;

type NameValue = { name: string; value: string };
type NameSeries = { name: string; series: NameValue[] };
type CashflowResults = { name: string; series: NameSeries[] };

@Component({
  selector: 'prosumer-compare-cashflow',
  templateUrl: './compare-cashflow.component.html',
  styleUrls: ['./compare-cashflow.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareCashflowComponent extends BaseComponent implements OnInit {
  private cashflowResultsSubject = new BehaviorSubject<CashflowResults[]>([]);
  data: Array<any> = [];
  tooltipData: Array<any> = [];
  chartWidth$ = new BehaviorSubject(0);
  chartHeight = 500;
  chartCustomColors: Array<any>;
  customColorList: Array<any>;
  barPadding = DEFAULT_BAR_PADDING;
  groupPadding = DEFAULT_GROUP_PADDING;
  @Input() colors: PerceptionMap;
  @Input() id: string;

  _customColors: Array<any>;
  @Input() set customColors(value: Array<any>) {
    this._customColors = value;
    this.mapData();
  }
  get customColors() {
    return this._customColors;
  }

  _cashFlowResults: Array<CompareCashFlowResultItem>;
  @Input() set cashFlowResults(value: Array<CompareCashFlowResultItem>) {
    this._cashFlowResults = value;
    this.mapData();
  }
  get cashFlowResults() {
    return this._cashFlowResults;
  }

  chartData$: Observable<StackedBarMeta>;

  /**
   * Maps custom colors and chart data to handle async interdependent customColors and cashflowResults inputs
   */
  mapData() {
    if (!!this.customColors && !!this.cashFlowResults) {
      this.mapCustomColors(this.customColors);
      this.data = this._mapToChartData(this.cashFlowResults);
      this.cashflowResultsSubject.next(this.data);
    }
  }

  /**
   * Maps the custom colors to chart colors
   *
   * @param value - the custom colors array
   */
  mapCustomColors(value: Array<any>) {
    this.customColorList = value;
    this.chartCustomColors = value;
    this.chartCustomColors = [
      ...this.chartCustomColors,
      ...value.map((color) => ({
        name: color.name + ':opex',
        value: color.value,
      })),
      ...value.map((color) => ({
        name: color.name + ':capex',
        value: this._lightenColor(color.value, 0.1),
      })),
    ];
  }

  private _lightenColor(color: string, amt: number): string {
    if (color.indexOf('#') !== -1) {
      let rgb = '#';
      for (let i = 0; i < 3; i++) {
        const startingPos = i * 2;
        const colorInt = parseInt(
          color.slice(1).slice(startingPos, startingPos + 2),
          16,
        );
        const colorStr = Math.round(
          Math.min(Math.max(0, colorInt + colorInt * amt), 255),
        ).toString(16);
        rgb += ('00' + colorStr).slice(colorStr.length);
      }
      return rgb;
    }
    return color;
  }

  private _mapToChartData(
    cashFlowResultList: Array<CompareCashFlowResultItem>,
  ) {
    const chartData = [];
    let scenarioNames = !!this.customColorList
      ? this.customColorList.map((sc) => sc.name)
      : [];
    const cashFlowResultByYearList$ = from(cashFlowResultList).pipe(
      groupBy((item) => item.year),
      mergeMap((group) => group.pipe(toArray())),
      map((arr) =>
        arr.reduce<CompareCashFlowResultItem>(
          (prev, next) => ({
            ...next,
            data: [...(next.data || []), ...prev.data],
          }),
          { year: '', data: [] },
        ),
      ),
    );
    cashFlowResultByYearList$.subscribe((retVal: CompareCashFlowResultItem) => {
      if (!!!scenarioNames) {
        const scNames = Array.from(
          new Set(retVal.data.map((scName) => scName.name)),
        );
        scenarioNames = [...scenarioNames, ...scNames];
      }
      chartData.push({
        name: retVal.year,
        series: this._getInnerSeriesData(scenarioNames, retVal.data),
      });
    });
    this.setChartWidth(
      this._calculateWidth(cashFlowResultList.length, scenarioNames.length),
    );
    return chartData;
  }

  /**
   * Constructs and retrieves the inner series data list.
   *
   * @param scenarioNames list of scenario names.
   * @param dataList list of cashflow result items value.
   * @return list of inner series data.
   */
  private _getInnerSeriesData(
    scenarioNames: Array<string>,
    dataList: Array<CompareCashFlowResultItemValue>,
  ) {
    const innerData = [];
    scenarioNames.forEach((scName) =>
      innerData.push({
        name: scName,
        series: dataList
          .filter((data) => data.name === scName)
          .map((opexCapexValue) => ({
            name: opexCapexValue.type,
            value: Number(
              this._inversePipe
                .transform(this._kiloPipe.transform(opexCapexValue.value))
                .toFixed(1),
            ),
          })),
      }),
    );
    return innerData;
  }

  /**
   * Constructor of the component.
   *
   * @param formBuilder formBuilder
   * @param elementRef elementRef
   * @param _kiloPipe kiloPipe
   * @param _inversePipe inversePipe
   */
  constructor(
    private _kiloPipe: KiloPipe,
    private _inversePipe: InversePipe,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.chartData$ = this.getChartDataStream();
  }

  setChartWidth(value: number) {
    this.chartWidth$.next(value);
  }

  /**
   * Calculates and set the width of the chart based on the number of data to be displayed.
   *
   * @param resultsLength number of years.
   * @param scenarioLength number of scenario
   * @param barWidth bar width
   * @param spacing spacing
   * @param offset offset
   * @param barPadding barPadding
   * @param groupPadding groupPadding
   */
  private _calculateWidth(
    resultsLength: number,
    scenarioLength: number,
    barWidth = DEFAULT_BAR_WIDTH,
    spacing = DEFAULT_SPACING,
    offset = DEFAULT_OFFSET,
    barPadding = DEFAULT_BAR_PADDING,
    groupPadding = DEFAULT_GROUP_PADDING,
  ) {
    return (
      ((barWidth + barPadding) * scenarioLength + groupPadding) *
        resultsLength +
      offset
    );
  }

  private getChartDataStream(): Observable<StackedBarMeta> {
    return this.cashflowResultsSubject.pipe(
      map((results) => this.mapResultsToStackedBarMeta(results)),
    );
  }

  private mapResultsToStackedBarMeta(
    results: CashflowResults[],
  ): StackedBarMeta {
    const data = this.mapResultsToBarData(results);
    return {
      name: 'Comparison of Cashflow',
      yAxisName: 'Discounted Expenditures [k€]',
      xAxisName: this.translate.instant('Result.labels.years'),
      axisTicks: this.extractYears(results),
      data,
    };
  }

  private extractYears(results: CashflowResults[]): string[] {
    return results.map((r) => r.name).sort();
  }

  private mapResultsToBarData(results: CashflowResults[]): BarDatum[] {
    const scenarioNames = results[0].series.map((siri) => siri.name);
    return scenarioNames.reduce(
      (acc, curr) => [...acc, ...this.createCapexOpexBarDatums(curr, results)],
      [],
    );
  }

  private createCapexOpexBarDatums(
    scenarioName: string,
    results: CashflowResults[],
  ): [BarDatum, BarDatum] {
    return [
      this.createBarDatum(scenarioName, results, 'capex'),
      this.createBarDatum(scenarioName, results, 'opex'),
    ];
  }

  private createBarDatum(
    group: string,
    results: CashflowResults[],
    category: string,
  ): BarDatum {
    return {
      group,
      name: `${group}_${category}`,
      values: this.reduceToYearlyValues(group, results, category),
      shade: this.resolveCategoricalShade(category),
    };
  }

  private resolveCategoricalShade(category: string): ColorShade {
    return category === 'capex' ? ColorShade.LIGHTEN : ColorShade.DARKEN;
  }

  private reduceToYearlyValues(
    scenarioName: string,
    results: CashflowResults[],
    category: string,
  ): { [name: string]: number } {
    return results.reduce((acc, curr) => {
      const scenarioSeries = this.findMatchingSiri(scenarioName, curr.series);
      const categoricalNameValue = this.findMatchingNameValue(
        category,
        scenarioSeries,
      );
      acc[curr.name] = categoricalNameValue?.value;
      return acc;
    }, {});
  }

  private findMatchingSiri(matcher: string, series: NameSeries[]): NameSeries {
    return series.find((siri) => siri.name === matcher);
  }

  private findMatchingNameValue(
    category: string,
    series: NameSeries,
  ): NameValue {
    return series.series.find((siri) => siri.name === category);
  }

  /**
   * Set the tooltip based on the selected bar in the graph.
   *
   * @param seriesModel bar in the graph.
   */
  getSeriesMembers(seriesModel: any) {
    this.tooltipData = [
      {
        name: seriesModel.value.name.replace(
          seriesModel.value.groupName + ':',
          '',
        ),
        value: seriesModel.value.value,
        groupName: seriesModel.value.groupName,
      },
    ];
  }
}

import moment from 'moment';
import { CompareDispatchResultItem } from 'prosumer-app/+scenario/models';
import { MONTHLY_DAYS } from 'prosumer-app/app.references';
import { getKeys, toTitleCase } from 'prosumer-app/libs/eyes-shared';
import { PipeUtils } from 'prosumer-core/utils';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { StorageDispatchComparison } from '../adapters';
import {
  CompareDispatchChartData,
  CompareDispatchChartSeriesData,
  CompareDispatchData,
} from '../compare-dispatch.model';
import { DaysInMonth } from '../monthly/monthly.model';

@Component({
  selector: 'prosumer-compare-monthly-storage',
  templateUrl: './monthly-storage.component.html',
  styleUrls: ['./monthly-storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareMonthlyStorageComponent
  extends StorageDispatchComparison
  implements AfterViewInit, OnInit
{
  options: string[];
  equipmentControl = new UntypedFormControl();
  data$ = new BehaviorSubject<CompareDispatchData>(null);
  @Input() customColors: any;

  @Input() set data(value: CompareDispatchData) {
    if (!!value && !!value.dataList) {
      this.options = this.getEquipmentOptions(value.dataList);
      this.data$.next(value);
    }
  }

  name$ = this.data$.pipe(
    filter((data) => !!data),
    map((data) => data.name),
    this.takeUntilShare(),
  );

  ngxChartData$ = combineLatest([
    this.data$,
    this.equipmentControl.valueChanges,
  ]).pipe(
    filter(([data, equipment]) => !!data && !!equipment),
    map(([data, equipment]) => this.mapToChartData(data.dataList, equipment)),
    this.takeUntilShare(),
  );

  chartDataLength$ = this.ngxChartData$.pipe(
    filter((chartData) => !!chartData),
    map((data) => data.length),
    this.takeUntilShare(),
  );

  tooltipDisabled$ = this.chartDataLength$.pipe(
    map((length) => length <= 0),
    this.takeUntilShare(),
  );

  constructor(
    private _translate: TranslateService,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }
  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  ngAfterViewInit() {
    if (!!this.options && this.options.length > 0) {
      this.equipmentControl.patchValue(this.options[0]);
    }
  }

  /**
   * Get the equipment options based on the result items
   *
   * @param resultItems - the result items
   */
  getEquipmentOptions(resultItems: CompareDispatchResultItem[]) {
    if (!resultItems || resultItems.length === 0) {
      return [];
    }
    return [
      ...new Set(
        resultItems
          .map((mapValue) => mapValue.equipment)
          .filter((filterValue) => !!filterValue),
      ),
    ];
  }

  /**
   * Filter the resultItems parameter by equipment parameter then map to the chart data
   *
   * @param resultItems - the list of dispatch result items
   * @param equipment   - the equipment for filtering the resultItems
   */
  mapToChartData(
    resultItems: CompareDispatchResultItem[],
    equipment: string,
  ): Array<CompareDispatchChartData> {
    if (!resultItems || resultItems.length === 0) {
      return [];
    }

    // Filter by equipment
    const storageData = resultItems.find(
      (value) => value.equipment === equipment,
    );

    if (
      !storageData ||
      !storageData.values ||
      storageData.values.length === 0
    ) {
      return [];
    }

    // Generate the series data list based on the values and year
    const seriesDataList = this.generateSeriesDataList(
      storageData.values,
      storageData.year,
    );

    // Group the generated series data list by hour

    const seriesDataListGroupedByMonth = this.groupSeriesDataListByMonth(
      seriesDataList,
      this.generateDaysInMonth(),
    );

    // Group the generated series data by charge then return
    return this.groupSeriesDataListByCharge(seriesDataListGroupedByMonth);
  }

  /**
   * Generates days in months array
   *
   * @param year - the year used for calculating the leap-year
   * @param calculated - true if leap-year will be calculated
   */
  generateDaysInMonth(): Array<DaysInMonth> {
    return getKeys(MONTHLY_DAYS).map((key, index) => ({
      index,
      name: toTitleCase(key),
      short: toTitleCase(key).substring(0, 3),
      value: MONTHLY_DAYS[key],
    }));
  }

  /**
   * Generates the series data list based on the values parameter for the current year parameter
   *
   * @param values  - list of values representing the 8760 hours in a year
   * @param year    - the year to refer when creating a moment date
   */
  generateSeriesDataList(
    values: number[],
    year: string,
  ): CompareDispatchChartSeriesData[] {
    if (!values || values.length === 0) {
      return [];
    }

    return values.map((value, index) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hour: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .month(index)
        .get('h'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      name: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .month(index)
        .toISOString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      moment: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      }).hour(index),
      value: value * -1, // Negate value as per requirement,
    }));
  }

  /**
   * Groups the series data list by hour in a day (1 to 24)
   *
   * @param seriesDataList - the series data list to group
   */
  groupSeriesDataListByMonth(
    seriesDataList: CompareDispatchChartSeriesData[],
    daysInMonth: DaysInMonth[],
  ): CompareDispatchChartSeriesData[] {
    let startHour = 0;
    return daysInMonth.map((month) => {
      const endHour = startHour + month.value * 24;
      const monthDataSet = !!seriesDataList
        ? seriesDataList.slice(startHour, endHour)
        : [];
      const monthData = {
        name: month.name,
        value: monthDataSet.reduce(
          (prev, next) => ({ ...next, value: prev.value + next.value }),
          { value: 0 },
        ).value,
      };
      startHour = endHour;
      return monthData;
    });
  }

  /**
   * Groups the series data list by charge (positive or negative)
   *
   * @param seriesDataList - the series data list to group
   */
  groupSeriesDataListByCharge(
    seriesDataList: CompareDispatchChartSeriesData[],
  ) {
    if (!seriesDataList || seriesDataList.length === 0) {
      return [];
    }

    return seriesDataList.map((data) => {
      if (data.value < 0) {
        return this.mapSeriesDataToChartData(data, 'Storage Discharge');
      } else {
        return this.mapSeriesDataToChartData(data, 'Storage Charge');
      }
    });
  }

  /**
   * Maps the series data to the chart data
   *
   * @param seriesData - the series data
   * @param name       - the name of the series data
   */
  mapSeriesDataToChartData = (
    seriesData: CompareDispatchChartSeriesData,
    name: string,
  ): CompareDispatchChartData => {
    if (!seriesData) {
      return {
        name: null,
        series: [],
      };
    }

    return {
      name: seriesData.name,
      series: [
        {
          name,
          value: seriesData.value,
        },
      ],
    };
  };

  formatXAxisTickLabel = (value: any) => value;

  formatTooltipTitle = (obj: any) =>
    obj
      ? `${this._translate.instant('Result.labels.month')} ${
          (obj || {}).series || ''
        }`.trim()
      : null;

  formatTick(tick: string): string {
    return tick;
  }
  getChartName(): string {
    return `Monthly Storage Dispatch - ${this.equipmentControl.value} (${this.data$.value?.name})`;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.ngxChartData$.pipe(PipeUtils.filterOutEmpty) as Observable<
      VisualizerData[]
    >;
  }
  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return { ...data, xAxisName: 'Months', yAxisName: 'kWh' };
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TopologyDialogPrepper } from 'prosumer-app/+scenario/services/dialog-data-preppers';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  containsSubstring,
} from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { LineStore } from 'prosumer-app/stores/line';
import { NodeInfo, NodeQuery, NodeStore } from 'prosumer-app/stores/node';
import { take } from 'rxjs/operators';
import { Node, NodesFormDialogData } from '../../../models';
import { TopologyNodesDialogComponent } from '../topology-nodes-dialog';

@UntilDestroy()
@Component({
  selector: 'prosumer-topology-nodes-table',
  templateUrl: './topology-nodes-table.component.html',
  styleUrls: ['./topology-nodes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopologyNodesTableComponent {
  @Input() viewOnly = false;

  nodesList$ = this.nodeQuery.selectActiveNodes().pipe(untilDestroyed(this));
  nodesLoading$ = this.nodeQuery.selectLoading().pipe(untilDestroyed(this));

  nodeColumnDef: ColumnDefinition = {
    name: {
      name: 'Node Name',
      sortable: true,
    },
    surface: {
      name: 'Surface',
      flex: '20%',
      sortable: true,
    },
  };

  nodeWithActionsDef: ColumnDefinition = {
    ...this.nodeColumnDef,
    actions: {
      name: 'Actions',
      type: 'action',
      flex: '23%',
    },
  };

  constructor(
    private readonly nodeQuery: NodeQuery,
    public readonly nodeStore: NodeStore,
    private readonly lineStore: LineStore,
    private readonly notification: NotificationsService,
    private readonly dialog: DialogService,
    private readonly prepper: TopologyDialogPrepper,
  ) {}

  onEdit(node: NodeInfo) {
    this.dialog.openDialog(
      TopologyNodesDialogComponent,
      this.buildEditData(node),
    );
  }

  onAdd() {
    this.dialog.openDialog(
      TopologyNodesDialogComponent,
      this.prepper.prepNodeData(),
    );
  }

  onDelete(node: Node) {
    this.nodeStore
      .deleteNode(node.value)
      .pipe(take(1))
      .subscribe({
        next: () => this.getLines(),
        error: (err: HttpErrorResponse) =>
          this.notification.showError(err.error.error),
      });
  }

  isNameSubstring(data: NodeInfo, query: string): boolean {
    return containsSubstring(data.name, query);
  }

  private getLines(): void {
    this.lineStore.improvedGetAll().pipe(take(1)).subscribe();
  }

  private buildEditData(node: Node): NodesFormDialogData {
    return {
      ...this.prepper.prepNodeData(),
      mode: 'edit',
      nodeData: this.nodeQuery.mapNodeToInfo(node),
      existingNodes: this.nodeQuery.getNodes(),
    };
  }
}

<form [formGroup]="vreForm">
  <div fxLayout="column">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="vre-form-title-general-parameters">
            {{ 'Scenario.labels.general.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_renewable.wizard_renewable_general'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            fxFlex="33%"
            module="Scenario"
            formFieldName="der.name"
            placeholder="Input a name for your VRE"
            [attr.data-testid]="'renewable-generator-name'"
            [inputTooltip]="'wizard_renewable.wizard_renewable_name'"
            [control]="vreForm?.controls?.name"
            [required]="true"
          ></prosumer-input>
          <prosumer-select
            fxFlex="33%"
            placeholder="Select output energy vector"
            [label]="'Scenario.labels.technical.outputEnergyVector' | translate"
            [options]="outputEnergyVectorOptions"
            [control]="vreForm?.controls?.outputEnergyVector"
            [required]="true"
            [errorMessageMap]="errorMessages?.outputEnergyVector"
            [tooltip]="'wizard_renewable.wizard_renewable_output_vector'"
            data-testid="output-energy"
          ></prosumer-select>
          <!-- Scenario Variation -->
          <prosumer-select
            fxFlex="33%"
            *ngIf="scenarioVariationOptions.length > 1"
            [placeholder]="
              'Scenario.placeholders.der.scenarioVariation' | translate
            "
            [label]="'Scenario.labels.der.scenarioVariation' | translate"
            [options]="scenarioVariationOptions"
            [control]="vreForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages['scenarioVariation']"
            data-testid="renewable-generator-variation"
          ></prosumer-select>
        </div>
        <prosumer-node-filterchip-component
          *ngIf="isMultiNode"
          [required]="true"
          [nodeOptions]="nodeOptions"
          formControlName="nodes"
          [errorMessage]="errorMessages?.node"
          [setSubmitted]="submitted$ | async"
          data-testid="renewable-generator-node"
        ></prosumer-node-filterchip-component>

        <prosumer-tdb-renewables
          *ngIf="data.hasTdbAcces"
          [profileFiltersSourceDetails]="data.equipment.tdbProfileFilters"
          [filterAction]="'getRenewableFilters'"
          [profileAction]="'getRenewableProfile'"
          (profileDataFetched)="handleTdbProfileData($event)"
          data-testid="vre-profiles-tdb-module"
        ></prosumer-tdb-renewables>

        <div fxLayout="column">
          <prosumer-yearly-loads-interval
            formControlName="profiles"
            [required]="true"
            [startYear]="vreForm?.controls?.startYear.value"
            [endYear]="vreForm?.controls?.endYear.value"
            [defaultLocation]="'ders.profile'"
            [useLibraryPanel]="false"
            [mode]="data.mode"
            [loading]="binaryLoading$ | async"
            [yearlyLoadMessages]="yearlyLoadMessage"
            [alwaysCustom]="true"
            xAxisLabel="Hours"
            yAxisLabel="kW/kWi"
            [yearlyLoadEnabled]="false"
            (selectedTabChange)="onSelectInterval($event)"
          >
          </prosumer-yearly-loads-interval>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-radio-button
            [label]="'Scenario.labels.general.source' | translate"
            [options]="profileOptions"
            [control]="vreForm?.controls?.sourceType"
            [labelTooltip]="'wizard_renewable.wizard_renewable_source'"
            data-testid="source-options"
          >
          </prosumer-radio-button>
        </div>
        <div>
          <prosumer-tdb-technology-filters
            *ngIf="vreForm?.controls?.sourceType?.value === 'tdb'"
            [extraDataQueryParams]="extraQueryParamsTDB"
            [technologyType]="derTypes.vre"
            (loadingState)="handleTechnologyFiltersLoading($event)"
            (dataSelected)="handleTdbTechnologyData($event)"
          ></prosumer-tdb-technology-filters>
        </div>
        <div
          *ngIf="vreForm?.controls?.sourceType?.value === 'library'"
          fxLayout="column"
          fxLayoutGap="10px"
        >
          <prosumer-sparkline-table
            maxHeight="200px"
            [columnsDef]="columnsDef"
            [data]="library$ | async"
            [selected]="vreForm.controls.library?.value?.id"
            [loading]="libraryService.loading$ | async"
            [error]="libraryService.error$ | async"
            [messages]="messages"
            [hideAdd]="true"
            [hideEdit]="true"
            [hideDelete]="true"
            (selectionChanged)="onSelect($event)"
            data-testid="library-selector"
          >
          </prosumer-sparkline-table>

          <!-- Using class 'hidden' will use a special mat-form-field to be used when displaying only the error message -->
          <mat-form-field class="hidden">
            <input matInput [formControl]="vreForm?.controls?.library" />
            <mat-error
              *ngIf="vreForm?.controls?.library?.errors?.required"
              [innerHTML]="'Scenario.messages.der.library.required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>

        <span
          *ngIf="!!data.equipment.tdbTechnologyFilter"
          class="reference-info"
        >
          <span>*Reference: </span>
          <span class="data">{{
            data.equipment.tdbTechnologyFilter | referenceStr
          }}</span>
        </span>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.technical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_renewable.wizard_renewable_technical_parameters'
              "
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.capacityLoss' | translate"
            [control]="vreForm?.controls?.capacityLoss"
            [errorMessageMap]="errorMessages?.capacityLoss"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_capacity_loss'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyCapacityLoss"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.capacityLoss'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_capacity_loss'"
            [inputLabel]="'Scenario.labels.technical.capacityLoss' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-capacity-loss'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            *ngIf="isMultiNode"
            [formControl]="vreForm?.controls?.yearlyFootprint"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.footprint'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_footprint'"
            [inputLabel]="'Scenario.labels.technical.footprint' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-footprint'"
          >
          </prosumer-yearly-chart-input>

          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Scope3 Emissions Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.co2Emissions.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_renewable.wizard_renewable_scope3emissions_parameters'
              "
            >
            </prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Build emissions kW -->
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [control]="vreForm?.controls?.buildEmissionsKw"
            [label]="
              'Scenario.labels.co2Emissions.buildEmissionsKw' | translate
            "
            [errorMessageMap]="errorMessages?.buildEmissionsKw"
            [required]="true"
            [inputTooltip]=""
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyBuildEmissionsKw"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKw'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKw' | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            [attr.data-testid]="'vre-build-emission'"
          >
          </prosumer-yearly-chart-input>
          <!-- Build emissions indivisible -->
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [control]="vreForm?.controls?.buildEmissionsIndivisible"
            [label]="
              'Scenario.labels.co2Emissions.buildEmissionsIndivisible'
                | translate
            "
            [errorMessageMap]="errorMessages?.buildEmissionsIndivisible"
            [required]="true"
            [inputTooltip]=""
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyBuildEmissionsIndivisible"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.buildEmissionsIndivisible'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsIndivisible'
                | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            [attr.data-testid]="'vre-build-emission-indivisible'"
          >
          </prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" class="sizing-parameters">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.sizing.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_renewable.wizard_renewable_sizing_parameters'"
            >
            </prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [control]="vreForm?.controls?.minPower"
            [label]="'Scenario.labels.technical.minPower' | translate"
            [errorMessageMap]="errorMessages?.minPower"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_minimum_power'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyMinPower"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.minPower'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_minimum_power'"
            [inputLabel]="'Scenario.labels.technical.minPower' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-min-power'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-input
            type="number"
            placeholder="100000.0"
            fxFlex="24%"
            *ngIf="false"
            [control]="vreForm?.controls?.maxPower"
            [label]="'Scenario.labels.technical.maxPower' | translate"
            [errorMessageMap]="errorMessages?.maxPower"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_maximum_power'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyMaxPower"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.maxPower'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_maximum_power'"
            [inputLabel]="'Scenario.labels.technical.maxPower' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-max-power'"
          >
          </prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Forced Investment -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.forcedInvestment' | translate"
            [tooltip]="'wizard_renewable.wizard_renewable_forced_investment'"
            [control]="vreForm?.controls?.forcedInvestment"
            [attr.data-testid]="'vre-investment-option'"
          ></prosumer-checkbox>

          <!-- Existing Asset -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.existingAsset' | translate"
            [tooltip]="'wizard_renewable.wizard_renewable_existing_asset'"
            [control]="vreForm?.controls?.existingAsset"
            [errorMessageMap]="errorMessages?.existingAsset"
            [attr.data-testid]="'vre-existing-asset'"
          ></prosumer-checkbox>

          <!-- Capacity Expansion -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.capacityExpansion' | translate"
            [tooltip]="'wizard_renewable.wizard_renewable_capacity_expansion'"
            [control]="vreForm?.controls?.capacityExpansion"
            [attr.data-testid]="'vre-capacity-expansion-option'"
          ></prosumer-checkbox>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.economical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_renewable.wizard_renewable_economical_parameters'
              "
            >
            </prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.buildCost' | translate"
            [control]="vreForm?.controls?.buildCost"
            [errorMessageMap]="errorMessages?.buildCost"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_build_cost'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyBuildCost"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.buildCost'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_build_cost'"
            [inputLabel]="'Scenario.labels.technical.buildCost' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-build-cost'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.indivisibleCost' | translate"
            [control]="vreForm?.controls?.indivisibleCost"
            [errorMessageMap]="errorMessages?.indivisibleCost"
            [required]="true"
            [inputTooltip]="
              'wizard_renewable.wizard_renewable_indivisible_cost'
            "
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyIndivisibleCost"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.indivisibleCost'"
            [contextHelpMsg]="
              'wizard_renewable.wizard_renewable_indivisible_cost'
            "
            [inputLabel]="
              'Scenario.labels.technical.indivisibleCost' | translate
            "
            fxFlex="24%"
            [attr.data-testid]="'vre-indivisible-cost'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.fomCharge' | translate"
            [control]="vreForm?.controls?.fOAndMCharge"
            [errorMessageMap]="errorMessages?.fOAndMCharge"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_fom_charge'"
            [inputTooltipPosition]="'top-right'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyFOAndMCharge"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.fOAndMCharge'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_fom_charge'"
            [inputLabel]="'Scenario.labels.technical.fomCharge' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-FOAndMCharge'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-input
            type="number"
            placeholder="0.0"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.fomInstall' | translate"
            [control]="vreForm?.controls?.fOAndMPerInstall"
            [errorMessageMap]="errorMessages?.fOAndMPerInstall"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_fom_install'"
            [inputTooltipPosition]="'top-right'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyFOAndMInstall"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.fOAndMPerInstall'"
            [contextHelpMsg]="'wizard_renewable.wizard_renewable_fom_install'"
            [inputLabel]="'Scenario.labels.technical.fomInstall' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-FOAndMInstall'"
          >
          </prosumer-yearly-chart-input>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            type="number"
            placeholder="20"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.technicalLife' | translate"
            [control]="vreForm?.controls?.technicalLife"
            [errorMessageMap]="errorMessages?.technicalLife"
            [required]="true"
            [inputTooltip]="'wizard_renewable.wizard_renewable_technical_life'"
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyTechnicalLife"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.technicalLife'"
            [contextHelpMsg]="
              'wizard_renewable.wizard_renewable_technical_life'
            "
            [inputLabel]="'Scenario.labels.technical.technicalLife' | translate"
            fxFlex="24%"
            [attr.data-testid]="'vre-technical-life'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-input
            type="number"
            placeholder="0"
            fxFlex="24%"
            *ngIf="false"
            [label]="'Scenario.labels.technical.depreciationTime' | translate"
            [control]="vreForm?.controls?.depreciationTime"
            [errorMessageMap]="errorMessages?.depreciationTime"
            [required]="true"
            [inputTooltip]="
              'wizard_renewable.wizard_renewable_depreciation_time'
            "
          >
          </prosumer-input>
          <prosumer-yearly-chart-input
            [formControl]="vreForm?.controls?.yearlyDepreciationTime"
            [startYear]="vreForm?.controls?.startYear?.value"
            [endYear]="vreForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.depreciationTime'"
            [contextHelpMsg]="
              'wizard_renewable.wizard_renewable_depreciation_time'
            "
            [inputLabel]="
              'Scenario.labels.technical.depreciationTime' | translate
            "
            fxFlex="24%"
            [attr.data-testid]="'vre-depreciation-time'"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyCostOfFinancing"
            inputLabel="Scenario.labels.economical.costOfFinancing"
            errorMessage="Scenario.messages.der.costOfFinancing"
            contextHelpMsg="wizard_renewable.wizard_renewable_cost_of_financing"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="vre-yearly-cost-of-financing"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyCostOfDecommissioning"
            errorMessage="Scenario.messages.der.costOfDecommissioning"
            inputLabel="Scenario.labels.economical.costOfDecommissioning"
            contextHelpMsg="wizard_renewable.wizard_renewable_cost_of_decommissioning"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            [isNullable]="true"
            [minValue]="undefined"
            data-testid="vre-yearly-cost-of-decommissioning"
          >
          </prosumer-yearly-chart-input>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Operational Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.operationalCost.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_renewable.wizard_renewable_operational_cost_parameters'
              "
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <prosumer-operating-cost-table
        formControlName="operatingCostProfiles"
        [startYear]="vreForm?.controls?.startYear?.value"
        [endYear]="vreForm?.controls?.endYear?.value"
        [mode]="data?.mode"
        [formType]="formType"
        [outputEnergyVectorsOptions]="outputEnergyVectorOptions$ | async"
        [projectId]="projectId"
        [caseId]="caseId"
        [scenarioId]="scenarioId"
        [attr.data-testid]="'vre-operating-cost-table'"
      ></prosumer-operating-cost-table>
    </mat-expansion-panel>
  </div>

  <button #submit type="submit" [hidden]="true"></button>
</form>

import {
  provideUpserter,
  ScenarioBinData,
  ScenarioBinStore,
} from 'prosumer-app/stores';
import { MobilityRouteCreator } from 'prosumer-app/stores/mobility-route';
import { BaseComponent, CustomValidators } from 'prosumer-libs/eyes-shared';
import { LibraryLoads, ProfileRoutes } from 'prosumer-scenario/models';
import { YearlyLoadsIntevalValidators } from 'prosumer-shared/components';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { ProfileFormHelperService } from 'prosumer-shared/services';
import { NameValidator } from 'prosumer-shared/validators';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RoutesFormDialogData } from './routes-form-dialog.model';

@Component({
  selector: 'prosumer-mobility-routes-form',
  templateUrl: './routes-form-dialog.component.html',
  styleUrls: ['routes-form-dialog.component.scss'],
  providers: [ProfileFormHelperService, provideUpserter(MobilityRouteCreator)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilityRoutesFormDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  activeIndex = 0;
  isViewOnly: boolean;

  routesForm = this.formbuilder.group({
    name: [
      '',
      [
        Validators.required,
        NameValidator.invalidCharacters(),
        NameValidator.maxCharLength(),
        NameValidator.whiteSpaceOnly(),
      ],
    ],
    id: [''],
    profileRoutes: [],
  });

  binaryLoading$: Observable<boolean> = this._scenarioBinStore.loading$;

  yearlyLoadMessage: YearlyLoadMessageConfig = {
    loadTypeLabel: 'Scenario.labels.mobility.profileType',
    loadTypeTooltip: '',
    loadsDataPlaceholder: 'Scenario.labels.mobility.consumptionProfile',
    loadsDataLabelCustom: 'Scenario.labels.mobility.consumptionProfile',
    loadsDataTooltip:
      'wizard_mobility.wizard_mobility_route_consumption_profile',
    loadsDataRequired: 'Scenario.messages.mobility.profileRoutes.required',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RoutesFormDialogData,
    public dialogRef: MatDialogRef<MobilityRoutesFormDialogComponent>,
    public _scenarioBinStore: ScenarioBinStore,
    private formbuilder: UntypedFormBuilder,
    public profileFormHelperService: ProfileFormHelperService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.setFormValidators();
    this.loadFormData();

    this.isViewOnly = this.data.isViewOnly || false;
  }

  ngAfterViewInit(): void {
    this.addProfilesCtrlValidator();
  }

  setFormValidators(): void {
    this.routesForm.controls.name.setAsyncValidators(
      CustomValidators.dataExist(
        this.data.existingRoutesData$,
        'name',
        this.data.routeEditData,
      ),
    );
  }

  loadFormData(): void {
    this.routesForm.patchValue({
      name: this.data.name,
      id: this.data.id,
    });

    if (this.data.mode === 'edit' && this.data.profileRoutes) {
      this.routesForm.controls.profileRoutes.patchValue(
        this.data.profileRoutes.map((routes) => ({
          ...routes,
          loadProfile: routes.loadProfile || [],
        })),
      );
      if (
        !!!this.data.profileRoutes[0].loadProfile ||
        this.data.profileRoutes[0].loadProfile.length === 0
      ) {
        this.loadBinaryData(this.data.profileRoutes[0]);
      }
    }
  }

  loadBinaryData(selectedProfile: ProfileRoutes) {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1))
      .subscribe((loads) => {
        if (!!loads) {
          const profile = (loads || ({} as LibraryLoads)).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'bin',
          });
        }
      });
  }

  loadBinaryData$(selectedProfile: ProfileRoutes): Observable<ScenarioBinData> {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      return this._scenarioBinStore
        .get(
          this.data.scenarioIdentity.projectId,
          this.data.scenarioIdentity.caseId,
          this.data.scenarioIdentity.scenarioId,
          selectedProfile.location,
          selectedProfile.localId,
          false,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }

  onSelectInterval(value: { selectedProfile: ProfileRoutes; index: number }) {
    this.activeIndex = value.index;
    if (
      this.data.mode === 'edit' &&
      this.profileFormHelperService.forBinDownload(
        this.data.profileRoutes,
        value.selectedProfile.localId,
      )
    ) {
      this.loadBinaryData(value.selectedProfile);
    }
  }

  onSaveAttempt(): void {
    this.profileFormHelperService.setSubmitted();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private addProfilesCtrlValidator() {
    this.routesForm.controls.profileRoutes.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(false, true),
    ]);
    this.routesForm.controls.profileRoutes.updateValueAndValidity();
  }
}

import moment from 'moment';
import { CompareDispatchResultItem } from 'prosumer-app/+scenario/models';
import { STORAGE_TYPE } from 'prosumer-app/app.references';
import { sortByName } from 'prosumer-app/libs/eyes-shared';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { StorageDispatchComparison } from '../adapters';
import {
  CompareDispatchChartData,
  CompareDispatchChartSeriesData,
  CompareDispatchData,
} from '../compare-dispatch.model';

@Component({
  selector: 'prosumer-compare-daily-storage',
  templateUrl: './daily-storage.component.html',
  styleUrls: ['./daily-storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDailyStorageComponent
  extends StorageDispatchComparison
  implements AfterViewInit, OnInit
{
  options: string[]; // The equipent control options
  equipmentControl = new UntypedFormControl();
  data$ = new BehaviorSubject<CompareDispatchData>(null); // the dispatch data

  @Input() customColors: any;

  /**
   * Setter for data where the equipment options and the chart data will be derived
   *
   * @param value - the compare dispatch data
   */
  @Input() set data(value: CompareDispatchData) {
    if (!!value && !!value.dataList) {
      this.options = this.getEquipmentOptions(value.dataList);
      this.data$.next(value);
    }
  }

  // Observable for the scenario name derived from the dispatch data
  name$ = this.data$.pipe(
    filter((data) => !!data),
    map((data) => data.name),
    this.takeUntilShare(),
  );

  // Observable for the chart data derived from the dispatch data and the selected equipment
  ngxChartData$ = combineLatest([
    this.data$,
    this.equipmentControl.valueChanges,
  ]).pipe(
    filter(([data, equipment]) => !!data && !!equipment),
    map(([data, equipment]) => this.mapToChartData(data.dataList, equipment)),
    this.takeUntilShare(),
  );

  // Observable for the chart data length derived from the chart data
  chartDataLength$ = this.ngxChartData$.pipe(
    filter((chartData) => !!chartData),
    map((data) => data.length),
    this.takeUntilShare(),
  );

  // Observable derived from the length of the chart data to determine if tooltip will be disabled
  tooltipDisabled$ = this.chartDataLength$.pipe(
    map((length) => length <= 0),
    this.takeUntilShare(),
  );

  constructor(
    private _translate: TranslateService,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngAfterViewInit() {
    if (!!this.options && this.options.length > 0) {
      this.equipmentControl.patchValue(this.options[0]);
    }
  }

  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  /**
   * Get the equipment unique options based on the result items
   *
   * @param resultItems - the result items
   * @returns list of string resembling equipment options
   */
  getEquipmentOptions(resultItems: CompareDispatchResultItem[]): string[] {
    if (!resultItems || resultItems.length === 0) {
      return [];
    }
    return [
      ...new Set(
        resultItems
          .map((mapValue) => mapValue.equipment)
          .filter((filterValue) => !!filterValue),
      ),
    ];
  }

  /**
   * Filter the resultItems parameter by equipment parameter then map to the chart data
   *
   * @param resultItems - the list of dispatch result items
   * @param equipment   - the equipment for filtering the resultItems
   * @param resultType  - the type of result (default: {@link STORAGE_TYPE})
   * @returns list of mapped chart-compatible data {@link CompareDispatchChartData}
   */
  mapToChartData(
    resultItems: CompareDispatchResultItem[],
    equipment: string,
    resultType: string = STORAGE_TYPE,
  ): CompareDispatchChartData[] {
    if (!resultItems || resultItems.length === 0) {
      return [];
    }

    // Filter by equipment and result type
    const storageData = resultItems.find(
      (value) =>
        value.equipment === equipment && value.resultType === resultType,
    );

    if (
      !storageData ||
      !storageData.values ||
      storageData.values.length === 0
    ) {
      return [];
    }

    // Generate the series data list based on the values and year
    const seriesDataList = this.generateSeriesDataList(
      storageData.values,
      storageData.year,
    );

    // Group the generated series data list by hour
    const seriesDataListGroupedByHour =
      this.groupSeriesDataListByHour(seriesDataList);

    // Group the generated series data by charge then return
    return this.groupSeriesDataListByCharge(seriesDataListGroupedByHour);
  }

  /**
   * Generates the series data list based on the values parameter for the current year parameter
   *
   * @param values  - list of values representing the 8760 hours in a year
   * @param year    - the year to refer when creating a moment date (optional)
   * @returns generated list of chart-compatible series data {@link CompareDispatchChartData} based on the parameters
   */
  generateSeriesDataList(
    values: number[],
    year?: string,
  ): CompareDispatchChartSeriesData[] {
    if (!values || values.length === 0) {
      return [];
    }
    return values.map((value, index) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hour: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .hour(index)
        .get('h'),
      value: value * -1, // Negate value as per requirement,
    }));
  }

  /**
   * Groups the series data list by hour in a day (1 to 24)
   *
   * @param seriesDataList - the series data list to group
   * @returns list of chart-compatible series data {@link CompareDispatchChartData} grouped by hour (1-24)
   */
  groupSeriesDataListByHour(
    seriesDataList: CompareDispatchChartSeriesData[],
  ): CompareDispatchChartSeriesData[] {
    return Array.from(Array(24).keys()).map((hour) => {
      const hourSeriesDataList = (seriesDataList || []).filter(
        (seriesData) => seriesData && seriesData.hour === hour,
      );

      let totalHourSeriesData: CompareDispatchChartSeriesData;
      if (!hourSeriesDataList || hourSeriesDataList.length === 0) {
        totalHourSeriesData = {
          name: '' + (hour + 1),
          value: null,
          hour,
        };
      } else {
        totalHourSeriesData = hourSeriesDataList
          .map((hourSeriesData: any) => ({
            ...hourSeriesData,
            name: '' + (hour + 1),
          }))
          .reduce((prev, curr) => ({
            ...curr,
            value: prev.value + curr.value,
          }));
      }

      return {
        ...totalHourSeriesData,
        value: totalHourSeriesData.value / (hourSeriesDataList.length || 1),
      };
    });
  }

  /**
   * Groups the series data list by charge (positive or negative)
   *
   * @param seriesDataList - the series data list to group
   * @returns list of chart-compatible series data {@link CompareDispatchChartData} grouped by chart (positive/negative)
   */
  groupSeriesDataListByCharge(
    seriesDataList: CompareDispatchChartSeriesData[],
  ) {
    if (!seriesDataList || seriesDataList.length === 0) {
      return [];
    }

    const positive = seriesDataList
      .filter((data) => data.value >= 0)
      .map((value) => this.mapSeriesDataToChartData(value, 'Storage Charge'));
    const negative = seriesDataList
      .filter((data) => data.value < 0)
      .map((value) =>
        this.mapSeriesDataToChartData(value, 'Storage Discharge'),
      );

    return [...positive, ...negative].sort(sortByName);
  }

  /**
   * Maps the series data to the chart data
   *
   * @param seriesData - the series data
   * @param name       - the name of the series data
   * @returns the chart-compatible data {@link CompareDispatchChartData} based from the parameters
   */
  mapSeriesDataToChartData = (
    seriesData: CompareDispatchChartSeriesData,
    name: string,
  ): CompareDispatchChartData => {
    if (!seriesData) {
      return {
        name: null,
        series: [],
      };
    }

    return {
      name: (seriesData.hour + 1) as any,
      series: [
        {
          name,
          value: seriesData.value,
        },
      ],
    };
  };

  /**
   * Formats the tick label of the chart's x-axis
   *
   * @param value - the value of the data for a specific point in the x-axis
   * @returns the formatted value
   */
  formatXAxisTickLabel = (value: any) => value;

  /**
   * Formats the title of the tooltip
   *
   * @param obj - the value at a specific series
   * @returns the formatted value
   */
  formatTooltipTitle = (obj: any) =>
    obj
      ? `${this._translate.instant('Result.labels.hour')} ${
          (obj || {}).series || ''
        }`.trim()
      : null;

  formatTick(tick: string): string {
    return tick;
  }
  getChartName(): string {
    return `Daily Storage Dispatch - ${this.equipmentControl.value} (${this.data$.value?.name})`;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.ngxChartData$.pipe(PipeUtils.filterOutEmpty) as Observable<
      VisualizerData[]
    >;
  }
}

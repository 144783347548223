<form [formGroup]="form" #ngForm>
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title
        data-testid="general-form-scenario-setting-expansion-title"
        >{{ 'Scenario.labels.wizard.general.headers.settings' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_global.wizard_global_scenario_settings'"
          data-testid="general-form-scenario-setting-expansion-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="20px">
        <ngx-skeleton-loader
          *ngIf="loading; else showName"
          fxFlex="20%"
        ></ngx-skeleton-loader>
        <ng-template #showName>
          <p *ngIf="mode === 'read'; else showNameInput">
            <strong>{{ 'Scenario.labels.general.name' | translate }}: </strong
            >{{ form?.controls?.name?.value }}
          </p>
          <ng-template #showNameInput>
            <mat-form-field
              class="eyes-maximize-width"
              appearance="outline"
              [@fadeIn]
            >
              <mat-label data-testid="general-form-scenario-name-form-label">
                {{ 'Scenario.labels.general.name' | translate }}
              </mat-label>
              <input
                matInput
                formControlName="name"
                scenarioUpdater
                key="name"
                [view]="view"
                [hasSkip]="false"
                [value]="scenarioName$ | async"
                [nextValue]="nextValidScenarioName$ | async"
                [placeholder]="'Scenario.placeholders.general.name' | translate"
                (statusChange)="onStatusChange($event)"
                data-testid="general-form-scenario-name-form-input"
              />
              <mat-icon
                *ngIf="updateStatuses?.name?.status === 'success'"
                class="success icon-on-store-request"
                aria-hidden="false"
                data-testid="general-form-name-icon-success"
              >
                check_circle_outline
              </mat-icon>
              <mat-error
                *ngIf="form?.controls?.name?.errors?.required"
                [innerHtml]="'Scenario.messages.name.required' | translate"
                data-testid="general-form-name-error-required"
              >
              </mat-error>
              <mat-error
                *ngIf="form?.controls?.name?.errors?.invalidCharacter"
                [innerHtml]="
                  'Scenario.messages.name.invalidCharacter' | translate
                "
                data-testid="general-form-name-error-invalid-character"
              >
              </mat-error>
              <mat-error
                *ngIf="form?.controls?.name?.errors?.saveError"
                [innerHtml]="updateStatuses.name.message"
                data-testid="general-form-name-error-save"
              >
              </mat-error>
              <mat-error
                *ngIf="form?.controls?.name?.errors?.whiteSpaces"
                [innerHtml]="'Scenario.messages.name.whiteSpaces' | translate"
                data-testid="general-form-name-error-whiteSpaces"
              >
              </mat-error>
              <mat-error
                *ngIf="form?.controls?.name?.errors?.maxLength"
                [innerHtml]="'Scenario.messages.name.maxLength' | translate"
                data-testid="general-form-name-error-maxLength"
              >
              </mat-error>
            </mat-form-field>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="general-form-economics-expansion-title"
        >{{ 'Scenario.labels.wizard.general.headers.economics' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_global.wizard_global_economics'"
          data-testid="general-form-economics-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="20px">
        <ngx-skeleton-loader
          *ngIf="loading; else showWACC"
          fxFlex="10%"
        ></ngx-skeleton-loader>
        <ng-template #showWACC>
          <p *ngIf="mode === 'read'; else showWACCInput">
            <strong>{{ 'Scenario.labels.general.wacc' | translate }}: </strong
            >{{ form?.controls?.wacc?.value }}
          </p>
          <ng-template #showWACCInput>
            <mat-form-field
              class="eyes-maximize-width"
              appearance="outline"
              [@fadeIn]
            >
              <mat-label data-testid="general-form-economics-form-label">{{
                'Scenario.labels.general.wacc' | translate
              }}</mat-label>
              <input
                matInput
                formControlName="wacc"
                type="number"
                [placeholder]="'Scenario.placeholders.general.wacc' | translate"
                scenarioUpdater
                key="wacc"
                [view]="view"
                [invalid]="waccCtrl?.invalid"
                [value]="economicsWacc$ | async"
                [nextValue]="waccCtrl?.value"
                (statusChange)="onStatusChange($event)"
                data-testid="general-form-economics-form-input"
              />
              <mat-icon
                aria-hidden="false"
                *ngIf="
                  updateStatuses?.wacc?.status === 'success' && waccCtrl?.valid
                "
                class="success icon-on-store-request"
                data-testid="general-form-economics-icon-success"
                >check_circle_outline</mat-icon
              >
              <mat-icon
                aria-hidden="false"
                class="eyes-input-tooltip-anchor"
                matSuffix
                tooltipPosition="left"
                [prosumerTooltip]="'wizard_global.wizard_global_real_wacc'"
                data-testid="general-form-economics-form-input-tooltip"
                >help
              </mat-icon>
              <mat-error
                *ngIf="waccCtrl?.errors?.required"
                [innerHtml]="'Scenario.messages.wacc.required' | translate"
                data-testid="general-form-economics-form-error"
              >
              </mat-error>
              <mat-error
                *ngIf="waccCtrl.errors?.betweenZeroAndOne"
                [innerHtml]="
                  'Scenario.messages.wacc.betweenZeroAndOne' | translate
                "
                data-testid="general-form-economics-form-error-betweenZeroAndOne"
              >
              </mat-error>
              <mat-error
                *ngIf="form?.controls?.wacc?.errors?.saveError"
                [innerHtml]="updateStatuses.wacc.message"
              >
              </mat-error>
            </mat-form-field>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="general-form-energy-vector-expansion-title"
        >{{
          'Scenario.labels.wizard.general.headers.energyVectors' | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_global.wizard_global_energy_vectors'"
          data-testid="general-form-energy-vector-expansion-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutGap="5px" formGroupName="energyVectors">
      <mat-error
        *ngIf="
          isErrorState(energyVectorsForm) && energyVectorsForm?.errors?.required
        "
        class="eyes-error"
        [innerHTML]="
          'Scenario.messages.energyVectorsList.noSelected' | translate
        "
        data-testid="general-form-energy-vector-form-error"
      >
      </mat-error>

      <prosumer-energy-vector-select
        formControlName="predefined"
        [readonly]="mode === 'read'"
        [energyVectors]="predefinedEnergyVectors"
        [selectedEnergyVectors]="energyVectors$ | async"
        [showAdd]="true"
        (add)="onAddCustomEnergyVector()"
        (energyVectorSaved)="onEnergyVectorSaved($event)"
      >
      </prosumer-energy-vector-select>

      <prosumer-energy-vector-list
        [disabled]="form?.disabled"
        [readonly]="mode === 'read'"
        [name]="'Scenario.labels.energyVectors.customEnergyVectors' | translate"
        [energyVectors]="energyVectors$ | async"
        (delete)="onDeleteCustomEnergyVector($event)"
        [waitingResponse]="waitingResponse$ | async"
      >
      </prosumer-energy-vector-list>
      <mat-error
        *ngFor="let message of energyVectorMessages$ | async"
        class="eyes-error"
      >
        {{ message }}
      </mat-error>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel data-testid="scenario-variation-panel">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
      data-testid="scenario-variation-panel-header"
    >
      <mat-panel-title>
        {{
          'Scenario.labels.wizard.general.headers.scenarioVariations'
            | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_global.wizard_global_scenario_variations'"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-scenario-variation-list
      [scenarioName]="scenarioName$ | async"
      [isViewOnly]="mode === 'read'"
      formControlName="scenarioVariations"
      data-testid="general-scenario-variations"
    >
    </prosumer-scenario-variation-list>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
    (next)="onClickNext()"
    data-testid="general-stepper-nav"
  >
  </prosumer-stepper-nav>
</form>

<!-- Node Panel -->
<mat-expansion-panel
  [expanded]="expanded"
  data-testid="topology-node-creation-panel"
>
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title>
      {{ 'Scenario.labels.wizard.topology.headers.nodes' | translate }}
      <prosumer-tooltip-anchor
        [message]="'wizard_topology.wizard_topology_nodes'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="column">
    <div fxFlex.lt-lg="100%" fxFlex="100%">
      <prosumer-topology-nodes-table
        [viewOnly]="isViewOnly || mode === 'read'"
      ></prosumer-topology-nodes-table>
    </div>
  </div>
</mat-expansion-panel>
<!-- Lines Panel -->
<mat-expansion-panel
  [expanded]="expanded"
  data-testid="topology-line-creation-panel"
>
  <mat-expansion-panel-header
    [collapsedHeight]="collapsedHeight"
    [expandedHeight]="expandedHeight"
  >
    <mat-panel-title>
      {{ 'Scenario.labels.wizard.topology.headers.lines' | translate }}
      <prosumer-tooltip-anchor
        [message]="'wizard_topology.wizard_topology_lines'"
      >
      </prosumer-tooltip-anchor>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <prosumer-topology-lines-table
    [viewOnly]="isViewOnly"
  ></prosumer-topology-lines-table>
</mat-expansion-panel>

<prosumer-stepper-nav
  data-testid="topology-stepper-nav"
  [disabled]="disabled"
  [previousDisabled]="previousDisabled"
  [previousLabel]="previousLabel"
  [previousColor]="previousColor"
  [nextDisabled]="nextDisabled"
  [nextLabel]="nextLabel"
  [nextColor]="nextColor"
  [reverseButtons]="reverseButtons"
  (previous)="onClickPrevious()"
  (next)="onClickNext()"
>
</prosumer-stepper-nav>

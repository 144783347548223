import { Utils } from 'prosumer-app/core/utils';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioGenericStore } from 'prosumer-app/stores/scenario-generic';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ScenarioInfoService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ScenarioGenericResolver {
  constructor(
    private scenarios: ScenarioGenericStore,
    private readonly keeper: ActiveKeeperService,
    private readonly scenarioInfo: ScenarioInfoService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const scenario = this.extractScenarioId(route);
    this.keeper.setActive({ scenario });
    this.scenarios.setActive(scenario);
    this.scenarioInfo.getScenario().subscribe();
  }

  private extractScenarioId(route: ActivatedRouteSnapshot): string {
    return Utils.resolveToEmptyObject(route.params).scenarioId;
  }
}

<form [formGroup]="energyGridLimitForm" data-testid="market-limit-dialog-form">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Energy Grid Limit
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Market -->
        <prosumer-select
          fxFlex
          [placeholder]="
            'Scenario.placeholders.energyGridLimits.market' | translate
          "
          [label]="'Scenario.labels.energyGridLimits.market' | translate"
          [errorMessageMap]="errorMessages?.energyGridLimits.market"
          [options]="marketOptions"
          [control]="marketCtrl"
          [required]="true"
          [tooltip]="
            'wizard_commodities.wizard_market_limits_market' | translate
          "
          [attr.data-testid]="'market-limit-dialog-market'"
        >
        </prosumer-select>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" *ngIf="isMultiNode">
        <!-- Nodes -->
        <prosumer-generic-filterchip-component
          [placeholder]="
            'Scenario.placeholders.energyGridLimits.node' | translate
          "
          [label]="'Scenario.labels.energyGridLimits.node' | translate"
          fxFlex="100%"
          [required]="true"
          [tooltip]="'wizard_commodities.wizard_market_limits_node' | translate"
          [errorMessage]="errorMessages?.nodes.name"
          [setSubmitted]="submitted$ | async"
          formControlName="generics"
          [enableSelectAll]="true"
          [genericOptions]="nodeOptions"
          [attr.data-testid]="'market-limit-dialog-nodes'"
        >
        </prosumer-generic-filterchip-component>
      </div>
      <!-- Interval -->
      <div fxLayout="row" fxLayoutGap="10px" class="interval">
        <prosumer-energy-grid-limit-interval
          formControlName="energyGridLimitIntervals"
          [required]="true"
          [startYear]="startYearCtrl.value"
          [endYear]="endYearCtrl.value"
          (hasInvalidInterval)="hasInvalidInterval($event)"
        >
        </prosumer-energy-grid-limit-interval>
      </div>
      <div
        *ngIf="
          (submitted$ | async) &&
          (energyGridLimitIntervalsCtrl.errors?.required || invalidInterval)
        "
      >
        <mat-error
          [attr.data-testid]="'market-limit-dialog-interval-error'"
          [innerHtml]="
            'Scenario.messages.energyGridLimits.intervals.required' | translate
          "
        >
        </mat-error>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-market-limit-upsert-trigger
      *ngIf="!data?.isViewOnly"
      [id]="data.editData?.id"
      [disabled]="isFormPristine"
      [data]="formatFormValues()"
      [mode]="data.mode"
      [valid]="allValid$ | ngrxPush"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-market-limit-upsert-trigger>

    <button
      data-testid="market-limit-dialog-cancel"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
    >
      <span *ngIf="!data?.isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>

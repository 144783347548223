<mat-progress-bar
  color="accent"
  mode="indeterminate"
  data-testid="equipment-progress-bar"
  *ngIf="
    (binaryLoading$ | async) ||
    (libraryLoading$ | async) ||
    (loading$ | async) ||
    (detailsLoading$ | async) ||
    (creatorLoading$ | async) ||
    (isTdbReqLoading$ | async) ||
    (childFormsLoading$ | async)
  "
></mat-progress-bar>
<form
  fxLayout="column"
  class="equipmentFormDialog"
  [formGroup]="equipmentForm"
  (ngSubmit)="onConfirm()"
  data-testid="equipment-dialog-form"
>
  <div fxLayout="row" class="title-container">
    <h2 mat-dialog-title>{{ data?.mode | titlecase }} Equipment Option</h2>
    <prosumer-tooltip-anchor
      [message]="'wizard_renewable.wizard_renewable_equipment_type'"
    ></prosumer-tooltip-anchor>
  </div>

  <div fxLayout="column">
    <prosumer-select
      placeholder="Select Equipment Type"
      [label]="'Scenario.labels.technical.equipmentType' | translate"
      [options]="equipmentTypeOptions"
      [control]="equipmentForm?.controls?.type"
      [disabled]="disableTypeSelect"
      data-testid="select-equipment-type"
    ></prosumer-select>

    <div mat-dialog-content>
      <prosumer-vre
        [nodeOptions]="nodeOptions$ | async"
        [outputEnergyVectorOptions]="energyVectorOptions$ | async"
        *ngIf="equipmentForm?.controls?.type?.value === 'vre'"
      ></prosumer-vre>
      <prosumer-converter
        [nodeOptions]="nodeOptions$ | async"
        *ngIf="equipmentForm?.controls?.type?.value === 'converter'"
      ></prosumer-converter>
      <prosumer-storage
        [nodeOptions]="nodeOptions$ | async"
        [outputEnergyVectorOptions]="energyVectorOptions$ | async"
        *ngIf="equipmentForm?.controls?.type?.value === 'storage'"
      ></prosumer-storage>
      <prosumer-generator
        [nodeOptions]="nodeOptions$ | async"
        [energyVectorOptions]="energyVectorOptions$ | async"
        *ngIf="equipmentForm?.controls?.type?.value === 'generator'"
      ></prosumer-generator>
      <prosumer-vehicle
        [outputEnergyVectorOptions]="energyVectorOptions$ | async"
        *ngIf="equipmentForm?.controls?.type?.value === 'vehicle'"
      ></prosumer-vehicle>
      <prosumer-station
        [nodeOptions]="nodeOptions$ | async"
        [outputEnergyVectorOptions]="energyVectorOptions$ | async"
        *ngIf="equipmentForm?.controls?.type?.value === 'station'"
      ></prosumer-station>
    </div>

    <div mat-dialog-actions fxLayout="row">
      <button
        *ngIf="!isViewOnly"
        data-testid="equipment-dialog-confirm-button"
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="
          isOkDisabled ||
          !hasValidFormChanges() ||
          (binaryLoading$ | async) ||
          (detailsLoading$ | async) ||
          (creatorLoading$ | async) ||
          (loading$ | async)
        "
      >
        {{ 'Generic.labels.ok' | translate }}
      </button>

      <button
        data-testid="equipment-dialog-cancel-button"
        mat-flat-button
        type="button"
        color=""
        [disabled]="binaryLoading$ | async"
        (click)="onClose()"
      >
        <span *ngIf="!isViewOnly; else closeButton">{{
          'Generic.labels.cancel' | translate
        }}</span>
        <ng-template #closeButton>
          <span>{{ 'Generic.labels.close' | translate }}</span>
        </ng-template>
      </button>
    </div>
    <mat-error *ngIf="errorMsg$ | async as error">
      {{ error }}
    </mat-error>
  </div>
</form>
